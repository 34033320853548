import React, { useContext } from 'react'
import { Logo, TimerDisplay, Button } from '../..'
import { InstitutionContext } from '../../../store/InstitutionContext'
import { UrlContext } from '../../../store/UrlContext'

import './header.sass'
import { getExpiryTime, clearPersistState } from '../../../utils/PersistState'

interface IHeader {
  className?: string
  style?: React.CSSProperties
}

/**
 * Header
 *
 * @param props IHeader
 */
export const Header: React.FC<IHeader> = (props: IHeader) => {
  const institution = useContext(InstitutionContext)
  const { currentStep } = useContext(UrlContext)

  const stepsWithTimer = [
    'bsa-questionnaire',
    'invite-signers',
    'choose-amount',
    'choose-product-options',
    'choose-products',
    'personal-info',
    'selfie',
    'back',
    'front',
    'capture-documents',
    'terms-and-conditions',
  ]

  const onGoToStart = () => {
    if (getExpiryTime()) {
      const alertResult = window.confirm('Are you sure? This will start a new account opening process?')
      if (alertResult === true) {
        clearPersistState()
      } else {
        return
      }
    }
    // redirect through new request to clear state
    document.location.assign(`/${window.location.pathname.split('/')[1]}/onboarding`)
  }

  return (
    <header data-testid="header" className={'ni-header ' + props.className} style={props.style}>
      <Button onClick={onGoToStart} className="ni-logo is-ghost">
        <Logo url={institution?.logoUri?.default || ''} alt={`${institution?.name} logo`} />
      </Button>
      {stepsWithTimer.includes(currentStep) && <TimerDisplay />}
    </header>
  )
}
