import React from 'react'
import { IInstitution } from '../NimbleRouter'

interface Props {
  institution?: IInstitution
  children: React.ReactNode
}

export const InstitutionContext = React.createContext<IInstitution | undefined>(undefined)

export const InstitutionProvider: React.FC<Props> = ({ children, institution }) => {
  return <InstitutionContext.Provider value={institution}>{children}</InstitutionContext.Provider>
}
