import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { PersonalMortgageForm } from '../../../Forms/Personal/Loan/PersonalMortgageForm'
import { log } from '../../../../services'
import { LoadingContext } from '../../../../store/LoadingContext'

import {
  IPersonalMortgageLoanQuestionnaire,
  usePersonalMortgageLoan,
} from '../../../../store/Personal/Loan/PersonalMortgageLoanContext'

interface IStepPersonalMortgageQuestionnaire {
  className?: string
  style?: React.CSSProperties
}
export const StepPersonalMortgageLoanQuestionnaire: React.FC<IStepPersonalMortgageQuestionnaire> = (props) => {
  const history = useHistory()
  const { setLoading } = useContext(LoadingContext)
  const { personalMortgageLoan, updatePersonalMortgageLoanQuestionnaire } = usePersonalMortgageLoan()

  const handleSubmit = async (data: IPersonalMortgageLoanQuestionnaire) => {
    setLoading(true)
    log.info(JSON.stringify(data), 'StepPersonalMortgageLoanQuestionnaire')
    await updatePersonalMortgageLoanQuestionnaire(data)
    setLoading(false)

    history.push('/onboarding/success')
  }

  return (
    <div data-testid="step-personal-mortgage-loan-questionnaire" className={'ni-step-bsa ni-test'} style={props.style}>
      <h3>We need a little bit of information to get started...</h3>
      <h1>Please complete our questionnaire </h1>
      <PersonalMortgageForm onSubmit={handleSubmit} defaultValues={personalMortgageLoan.results} />
    </div>
  )
}
