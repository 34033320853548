import React, { useContext } from 'react'
import './StepVerifyEmail.sass'
import { useHistory } from 'react-router-dom'
import { useLoading, ProspectContext } from '../../../store'
import { AlertCircle, ArrowRight, Check } from 'react-feather'
import { Button, AlertMessage } from '../..'
import image from './send-email.svg'
import { useCreateEmailVerification } from '../../../hooks/useCreateEmailVerification'
import { log } from '../../../services'
import { IProspectState } from '../../../store/reducers/ProspectReducer'

interface IStep {
  className?: string
  style?: React.CSSProperties
}

const getSignerDataFromStorage = (prospectState: IProspectState) => {
  return {
    signerId: prospectState.signerId || '',
    email: prospectState.signer.email,
    token: prospectState.securityToken,
  }
}

/**
 * Step StepVerifyEmail
 *
 * @param props IStep
 */
export const StepVerifyEmail: React.FC<IStep> = (props: IStep) => {
  const history = useHistory()
  const { setLoading } = useLoading()
  const { status, error, mutate } = useCreateEmailVerification()
  const { prospect } = useContext(ProspectContext)

  const signerData = getSignerDataFromStorage(prospect)

  React.useEffect(() => {
    if (status === 'loading') {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [status, setLoading])

  const onResendClick = () => {
    const { signerId, token = null } = signerData
    log.info({ signerId, token }, 'onResendClick')
    mutate({ signerId, token })
  }

  const onFinishClick = () => {
    log.info('Go to Next Step', 'StepVerifyEmail')
    sessionStorage.removeItem('BSA')
    history.push('/onboarding/other-applicants')
  }

  return (
    <div data-testid="StepVerifyEmail" className={'ni-step-verify-email ' + props.className} style={props.style}>
      <div className="flex-center-vertical">
        {error && (
          <div role="alert" className="alert toast is-error" data-testid="error">
            <AlertCircle /> Sorry, there was a problem sending the email. Please try again later.
          </div>
        )}
        <img src={image} alt="Send Email illustration" className="img-fluid mt-4 mb-12" />
        <h1 className="letter-spacing-tight mb-12">Don’t forget to verify your email</h1>

        <p className="text-center hint">
          To ensure that your email is correct we sent you a verification email to <b>{signerData.email}</b>. You can
          verify your email address now or do it later.
        </p>
        <p className="text-center hint">If you didn’t receive our email just click on the “Resend Email” button.</p>

        {status === 'success' && (
          <AlertMessage title="New verification email sent" className="w100 text-center mt-6 mb-6" />
        )}

        <div className="flex-spaced-horizontal u-justify-center mt-6">
          <Button className={'is-pill m-3'} onClick={onResendClick} data-testid="resend-email">
            <ArrowRight /> {status === 'success' ? 'Sent!' : 'Resend Email'}
          </Button>
          <Button className="is-pill m-3 is-green" onClick={onFinishClick}>
            <Check /> Finish Request
          </Button>
        </div>
      </div>
    </div>
  )
}
