import React from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
} from '../..'

interface IConfirmEmailDialog {
  isOpen?: boolean
  onAction?: () => void
  onCancel?: () => void
}

/**
 * An AlertDialog for Repeated Emails.
 * If we are going to reuse this dialog, this component should be moved to /components
 * As of right now, this is used in just 1 context.
 *
 * @param props IConfirmEmailDialog
 */
export const ConfirmEmailDialog: React.FC<IConfirmEmailDialog> = (props: IConfirmEmailDialog) => {
  return (
    <AlertDialog open={props.isOpen}>
      <AlertDialogContent size="small" align="center">
        <div className="space-y-10">
          <AlertDialogTitle>Looks like you're inviting someone using your email.</AlertDialogTitle>

          <AlertDialogDescription className="ni-color-text">
            Please make sure that you are entering the email address of the account holder you wish to invite and not
            your own.
          </AlertDialogDescription>

          <AlertDialogDescription>
            <strong>Are you sure you want to use your email address?</strong>
          </AlertDialogDescription>

          <div className="flex-spaced-horizontal u-justify-center space-x-8">
            <AlertDialogCancel onClick={props.onCancel}>No</AlertDialogCancel>
            <AlertDialogAction onClick={props.onAction}>Yes</AlertDialogAction>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
