import React from 'react'

interface IPhotoPreview {
  className?: string
  style?: React.CSSProperties
  alt?: string
  imageData?: string
}

const defaultProps = {
  alt: 'Example',
}

/**
 * Preview a photo
 *
 * @param props IPhotoPreview
 */
export const PhotoPreview: React.FC<IPhotoPreview> = (props: IPhotoPreview) => {
  return (
    <div data-testid="PhotoPreview" className={'ni-photo-preview ' + props.className} style={props.style}>
      {props.imageData ? (
        <img alt={props.alt} src={props.imageData} className="photo" />
      ) : (
        <p>We couldn't capture your last photo. Can you try again?</p>
      )}
    </div>
  )
}

PhotoPreview.defaultProps = defaultProps
