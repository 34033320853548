import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as yup from 'yup'
import { Button, ErrorMessage, Input } from '../../index'
import { Radio } from '../Radio/Radio'
import { PhoneVerificationContext, ProspectContext } from '../../../store'
import PhoneVerificationService from '../../../services/PhoneVerification'
import CustomerLookupService, { ICustomerDetails } from '../../../services/CustomerLookup'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import './PhoneNumberVerificationForm.sass'
import { AlertCircle } from 'react-feather'

const phoneNumberRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
const schema = yup.object().shape({
  customerName: yup.string(),
  phoneNumber: yup
    .string()
    .required('Phone Number can’t be blank')
    .max(20, 'Phone Number can’t have more than 20 characters')
    .matches(/\([2-9]\d{2}\) [2-9]\d{2}-\d{4}/, 'Invalid phone number'),
})

export interface IPhoneVerificationDetails {
  phoneNumber?: string
  customerName?: string
}

interface IPhoneNumberVerificationForm {
  style?: React.CSSProperties
  className?: string
  loading?: boolean
  onSubmit: (data: IPhoneVerificationDetails) => void
}

/**
 * Verification Form
 *
 * @param props IPhoneNumberVerificationForm
 */
export const PhoneNumberVerificationForm: React.FC<IPhoneNumberVerificationForm> = (
  props: IPhoneNumberVerificationForm
) => {
  const { updateCustomerDetails, updateSelectedCustomer, updatePhoneNumber } = useContext(PhoneVerificationContext)
  const { prospect, updateSigner } = useContext(ProspectContext)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nameMenuOpen, setNameMenuOpen] = useState(false)
  const [selectedName, setSelectedName] = useState('')
  const [selectedNameIndex, setSelectedNameIndex] = useState(null)
  const [lookUpCustomer, setLookupCustomer] = useState(false)
  const [customersDetails, setCustomerDetails] = useState<ICustomerDetails[]>([])
  const { control, register, handleSubmit, errors } = useForm<IPhoneVerificationDetails>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const convertToE164UsNumber = (number: string): string => {
    let modifiedNumber = '+1' + number.replace(/-/g, '')
    return modifiedNumber
  }

  const findCustomer = async (phoneNumber: string) => {
    try {
      setLookupCustomer(true)
      const response = await CustomerLookupService.findCustomerByPhoneNumber(phoneNumber)
      setCustomerDetails(response)
      updateCustomerDetails(response) //move to state for email autoCompletion
      if (response.length === 0) {
        setSelectedName('Other')
      } else {
        //set all contantacts
        // setNameMenuOpen(true)
      }
    } catch (error) {
      setSelectedName('Other')
    }
    setLookupCustomer(false)
  }

  const onPhoneNumberChanged = (value: any) => {
    if (phoneNumberRegex.test(value.formattedValue)) {
      findCustomer(value.formattedValue)
    }
  }

  const handleNameSelected = (event: any, index: any) => {
    setSelectedName(event.target.value)
    setNameMenuOpen(false)
    if (event.target.value !== 'Other') {
      setSelectedNameIndex(index)
    }
  }
  const onFormSubmit = async (data: IPhoneVerificationDetails) => {
    setLoading(true)
    try {
      updatePhoneNumber(data.phoneNumber)
      updateSigner({ ...prospect.signer, phoneNumber: data.phoneNumber })
      data.phoneNumber = convertToE164UsNumber(data.phoneNumber!)
      const response = await PhoneVerificationService.createPhoneVerification({ phoneNumber: data.phoneNumber })
      // const response = { data: { sid: 'ww' } }
      if (response.data.sid) {
        if (!data.customerName && selectedName !== 'Other') {
          data.customerName = selectedName
        } else {
          data.customerName = 'User'
        }
        if (selectedNameIndex !== null) {
          const selectedCustomer = customersDetails[selectedNameIndex!]
          updateSelectedCustomer(selectedCustomer)
        }
        return props.onSubmit(data)
      }
      throw new Error('Failed to send message')
    } catch (error) {
      setErrorMessage((error as any).message || 'Failed to send message')
    }
    setLoading(false)
  }

  return (
    <div data-testid="PhoneNumberVerificationForm" className={'ni-signers-form ' + props.className} style={props.style}>
      {errorMessage && (
        <div role="alert" className="alert toast is-error">
          <AlertCircle /> {errorMessage}
        </div>
      )}
      <form className="form-look" onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <DropdownMenu.Root
            open={nameMenuOpen}
            onOpenChange={(open) => {
              setNameMenuOpen(open)
            }}
          >
            <DropdownMenu.Trigger asChild>
              <div></div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content side="left" className={'dropdown-menu-content'}>
              <div>
                {[
                  ...customersDetails.map((customer) => ({ label: customer.name, value: customer.name })),
                  { label: 'Other', value: 'Other' },
                ].map((field, index) => (
                  <Radio
                    key={field.value}
                    data-testid="name-radio"
                    name="selectedName"
                    value={field.value}
                    defaultValue={field.value}
                    label={field.value}
                    onChange={(event) => handleNameSelected(event, index)}
                  />
                ))}
              </div>
              <DropdownMenu.Arrow className={'dropdown-menu-arrow'} />
            </DropdownMenu.Content>
          </DropdownMenu.Root>
          <div>
            <div>
              <ErrorMessage errors={errors.phoneNumber as any} />
            </div>
            <Controller
              control={control}
              autoFocus
              data-testid={`input-phone`}
              name="phoneNumber"
              label="phone"
              className={`form-input-plain`}
              type="tel"
              format="(###) ###-####"
              placeholder="eg. (949) 942-5823"
              mask="_"
              defaultValue={''}
              onValueChange={onPhoneNumberChanged}
              as={NumberFormat}
            />
          </div>
        </div>
        {selectedName === 'Other' && (
          <Input
            name="customerName"
            label="Customer Name"
            type="text"
            defaultValue=""
            className="span-4"
            autoFocus={true}
            errors={errors.customerName}
            ref={register}
          />
        )}
        <div className="controls">
          <Button
            type="submit"
            loading={loading || lookUpCustomer}
            disabled={Object.keys(errors).length > 0 || loading || lookUpCustomer}
            className="is-pill is-green has-icon-after"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
