import React, { useState, useRef, useEffect } from 'react'
import { Button } from '../Common/Button/Button'
import DetectClickOutside from './ClickOutside'
import * as Icon from 'react-feather'
import './fab-help-button.sass'
import HelpIcon from './helpIcon.svg'

interface IFBAButton {
  className?: string
  style?: React.CSSProperties
  show?: boolean
}
export const FABHelpButton: React.FC<IFBAButton> = (props: IFBAButton) => {
  const { show } = props
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [isAtBottom, setIsAtBottom] = useState(false)
  useEffect(() => {
    // on some devices the fab is preventing the user from reaching the privacy button in the app footer
    // this aims to change the position of the fab when scrolled all the way to the bottom allowing access
    // to the app footer
    const scrolledToBottom = () => {
      const windowHeight = window.innerHeight
      const body = document.body
      const html = document.documentElement
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
      const windowBottom = windowHeight + window.pageYOffset
      if (windowBottom >= docHeight) {
        setIsAtBottom(true)
      } else {
        setIsAtBottom(false)
      }
    }
    window.addEventListener('scroll', scrolledToBottom)
  }, [isAtBottom, setIsAtBottom])

  // closes the fab component if a click outside is detected
  DetectClickOutside(ref, () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })
  const toggleHelp = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div
      className={`fabContainer ${isAtBottom ? 'bottom' : ''} ${show ? '' : 'hide'}`}
      ref={ref}
      data-testid="fab-container"
    >
      <div className={`helpContent ${isOpen ? `visible` : ''}`} data-testid="fab-help-content">
        <div>
          <h2>Need Help?</h2>
          <p>If you have questions or issues just reach out to our customer service.</p>
        </div>
        <h3>
          <Icon.Mail />
          support@nimblefi.com
        </h3>
        <h3>
          <Icon.Phone />
          (515) 222-1144
        </h3>
      </div>

      <Button onClick={toggleHelp} className="button is-blue fab" data-testid="fab-help-button">
        {isOpen ? (
          <div>
            <Icon.X size={32} /> <p className="screen-reader">Close help</p>
          </div>
        ) : (
          <div>
            <img src={HelpIcon} width={32} height={32} alt="help icon" />
            <p className="screen-reader">Open help</p>
          </div>
        )}
      </Button>
    </div>
  )
}
