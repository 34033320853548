import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ProspectContext } from '../../../store'

import './StepConfirmId.sass'
import { ConfirmIdForm } from '../../Forms/ConfirmIdForm/ConfirmIdForm'

interface IStepConfirmId {
  className?: string
  style?: React.CSSProperties
}

export const StepConfirmId: React.FC<IStepConfirmId> = (props: IStepConfirmId) => {
  const history = useHistory()
  const { prospect, updateSigner } = useContext(ProspectContext)

  const handleOnCompleted = async (documentId: string) => {
    updateSigner({ ...prospect.signer, documentNumber: documentId })
    history.push('/onboarding/selfie')
  }

  return (
    <div data-testid="step-confirm-id" className={`text-center`}>
      <div className="max-wrapper">
        <h3>Government ID</h3>
        <h2>
          {prospect.signer.documentNumber
            ? 'Please confirm that we were able to scan your government ID correctly'
            : 'Now we will need you to enter the ID on your scanned document'}
        </h2>
        <ConfirmIdForm documentId={prospect.signer.documentNumber} onCompleted={handleOnCompleted} />
      </div>
    </div>
  )
}
