import React from 'react'
import './StepTermsAndConditions.sass'
import { TermsAndConditions } from '../../Legal/TermsAndConditions/TermsAndConditions'

interface IStepTermsAndConditions {
  className?: string
  style?: React.CSSProperties
}

/**
 * IStepTermsAndConditions
 *
 * @param props StepTermsAndConditions
 */
export const StepTermsAndConditions: React.FC<IStepTermsAndConditions> = (props: IStepTermsAndConditions) => {
  return (
    <div
      data-testid="StepTermsAndConditions"
      className={`ni-step-terms-and-conditions ${props.className}`}
      style={props.style}
    >
      <div className="terms-content">
        <div className="info">
          <h1>
            <small>Before you start...</small>
            Important information about your account opening
          </h1>
          <p>
            On September 11, 2001, our lives changed forever. In an effort to protect you and our country, the USA
            PATRIOT Act was signed into law. To help the government fight the funding of terrorism and money laundering
            activities, Federal law requires all financial institutions to obtain, verify and record information that
            identifies each person who opens an account.
          </p>
          <p>
            When you open an account, we will ask for your name, address, date of birth and other information that will
            allow us to identify you. We may ask to see your driver’s license or other identifying documents.
          </p>
          <p>
            Please read and acknowledge to our terms of use by clicking the checkbox below to continue with your account
            opening.
          </p>
        </div>
        <div className="content">
          <TermsAndConditions />
        </div>
      </div>
    </div>
  )
}
