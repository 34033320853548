import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AlertCircle } from 'react-feather'
import * as yup from 'yup'
import { Button, Input } from '../..'
import './EmailEntryForm.sass'
import { ICustomerDetails } from '../../../services/CustomerLookup'

const schema = yup.object().shape({
  email: yup.string().required('Email is required'),
})

export interface IEmailFormDetails {
  email: string
}

interface IEmailEntryForm {
  style?: React.CSSProperties
  className?: string
  customerDetails: ICustomerDetails[]
  onCompleted: (email: string) => void
}

/**
 * Verification Form
 *
 * @param props IEmailEntryForm
 */
export const EmailEntryForm: React.FC<IEmailEntryForm> = (props: IEmailEntryForm) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { handleSubmit, register, errors } = useForm<IEmailFormDetails>({
    resolver: yupResolver(schema),
  })

  const onFormSubmit = async (data: IEmailFormDetails) => {
    setLoading(true)
    try {
      return props.onCompleted(data.email)
    } catch (error) {
      setErrorMessage((error as any).message || 'Failed to complete email entry')
    }
    setLoading(false)
  }

  return (
    <div data-testid="PhoneNumberVerificationForm" className={'ni-signers-form ' + props.className} style={props.style}>
      {errorMessage && (
        <div role="alert" className="alert toast is-error">
          <AlertCircle /> {errorMessage}
        </div>
      )}
      <form className="form-look" onSubmit={handleSubmit(onFormSubmit)}>
        <Input
          name="email"
          label="Email"
          autoComplete="email"
          type="email"
          placeholder="name@example.com"
          autoFocus
          className="span-8"
          errors={errors.email}
          ref={register}
        />

        <div className="controls">
          <Button
            type="submit"
            loading={loading}
            disabled={Object.keys(errors).length > 0 || loading}
            className="is-pill is-green has-icon-after"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
