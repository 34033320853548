import React, { useContext } from 'react'
import { QRCode } from 'react-qr-svg'
import { config } from '../../../services'
import { InstitutionContext } from '../../../store'
import './NoCameraMessage.sass'

interface INoCameraMessage {
  className?: string
  style?: React.CSSProperties
  title: string
  url?: string
  size?: number
  children?: React.ReactNode
}

const defaultProps = {
  title: 'Camera is not working',
  url: `${config.domain}/`,
  size: 128,
}

/**
 * NoCameraMessage
 *
 * @param props INoCameraMessage
 */
export const NoCameraMessage: React.FC<INoCameraMessage> = (props: INoCameraMessage) => {
  const institution = useContext(InstitutionContext)
  const url = institution ? props.url + institution.domain : props.url
  return (
    <div data-testid="NoCameraMessage" className={'ni-no-camera-message ' + props.className} style={props.style}>
      <h3>We need a camera to scan your ID</h3>
      <h2>{props.title}</h2>
      {props.children}
      <div className="columns">
        <div className="qrcode">
          <QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" style={{ width: props.size! }} value={url!} />
        </div>
        <ol className="steps">
          <li>Open your phone or tablet camera</li>
          <li>Point the camera to the QR code below</li>
          <li>A notification will prompt asking to open a link</li>
          <li>Click yes and finish the process</li>
        </ol>
      </div>
    </div>
  )
}

NoCameraMessage.defaultProps = defaultProps
