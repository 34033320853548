import React from 'react'
import Timer from 'react-compound-timer'
import './timer.sass'

interface ITimerDisplay {
  className?: string
  style?: React.CSSProperties
}

const twoDigits = (value: number): string => {
  if (value > 10) {
    return `${value}`
  }
  return `0${value}`
}

/**
 * TimerDisplay
 *
 * @param props ITimerDisplay
 */
export const TimerDisplay: React.FC<ITimerDisplay> = (props: ITimerDisplay) => {
  return (
    <div data-testid="timer" className={'ni-timer ' + props.className} style={props.style}>
      <small>Elapsed Time:</small>
      <div className="timer">
        <Timer startImmediately={true} formatValue={twoDigits}>
          <Timer.Minutes />:
          <Timer.Seconds />
        </Timer>
      </div>
    </div>
  )
}
