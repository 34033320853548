import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, ErrorMessage } from '../../index'
import { Controller } from 'react-hook-form'
import './ConfirmIdForm.sass'
import CodeInput from '../../Common/CodeInput/CodeInput'

const schema = yup.object().shape({
  documentId: yup
    .string()
    .required('Government id is required')
    .min(4, 'Government id cannot have less than 5 characters'),
})

export interface IConfirmDetails {
  documentId: string
}

interface IConfirmIdForm {
  style?: React.CSSProperties
  className?: string
  documentId?: string
  onCompleted: (documentId: string) => void
}

/**
 * Verification Form
 *
 * @param props IConfirmIdForm
 */
export const ConfirmIdForm: React.FC<IConfirmIdForm> = (props: IConfirmIdForm) => {
  const { control, handleSubmit, errors, setValue } = useForm<IConfirmDetails>({
    resolver: yupResolver(schema),
  })
  const fieldLength = props.documentId ? props.documentId.length : 15

  useEffect(() => {
    if (props.documentId) {
      setValue('documentId', props.documentId)
    }
  }, [props.documentId, setValue])

  const onFormSubmit = async (data: IConfirmDetails) => {
    props.onCompleted(data.documentId)
  }

  return (
    <div data-testid="ConfirmIdForm" className={'ni-signers-form ' + props.className} style={props.style}>
      <form className="phone-form-look" onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          control={control}
          name="documentId"
          as={
            <CodeInput
              fields={fieldLength}
              type="text"
              values={props.documentId ? props.documentId.split('') : undefined}
            />
          }
        />
        <ErrorMessage errors={errors.documentId as any} />
        <div className="controls">
          <Button type="submit" disabled={Object.keys(errors).length > 0} className="is-pill is-green has-icon-after">
            Correct
          </Button>
        </div>
      </form>
    </div>
  )
}
