import React from 'react'
import { Document } from '../Document/Document'
import './PrivacyPolicy.sass'

interface IPrivacyPolicy {
  close?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const PrivacyPolicy: React.FC<IPrivacyPolicy> = ({ close }) => {
  return (
    <div>
      <div className="text-center mb-12">
        <h1>Privacy Policy</h1>
      </div>

      <Document>
        <div>
          <section className="privacy-policy-section">
            <h2 id="title1">Introduction</h2>
            <p>
              SwitchTech LLC (“SwitchTech,” “we,” “our,” or “us”) respects the privacy of your information. This Privacy
              Policy is designed to assist you in understanding how we collect, use and safeguard the information you
              provide to us by using NimbleFi (“NimbleFi”) to open a checking account with your banking institution.
            </p>
            <p>
              [The collection, use, and sharing of your personal information may also be subject to your banking
              institution’s policies. Please contact your banking institution or visit their website to review their
              U.S. Consumer Privacy Notice, which offers you certain choices with respect to the use and sharing of your
              personal information.]
            </p>
            <p>
              From time to time, we may change this Privacy Policy. If we do, we will post an amended version on this
              webpage. Please review this Privacy Policy periodically.
            </p>
          </section>
          <section className="privacy-policy-section">
            <h2 id="title2">Collecting and Using Information</h2>
            <h3 id="title2-1">Personally Identifiable Information We Collect Online</h3>
            <p>
              We may collect Personally Identifiable Information from you through your use of NimbleFi. “Personally
              Identifiable Information” is individually identifiable information about an individual consumer that we
              collect online and that we maintain in an accessible form. We collect the following types of Personally
              Identifiable Information:
            </p>
            <p>
              <i>Information You Provide</i>
            </p>
            <p>
              We may collect the following Personally Identifiable Information that you voluntarily provide to us: first
              and last name, email address, telephone number, postal address, Social Security number, driver’s license
              number and photograph, date of birth, and your signature.
            </p>
            <p>
              <i>Information as You Navigate NimbleFi</i>
            </p>
            <p>
              We automatically collect certain Personally Identifiable Information through your use of NimbleFi, such as
              the following:
            </p>
            <ul>
              <li>
                <i>Usage Information. </i>We collect information such as which of the pages on NimbleFi you access, the
                frequency of access, and what you click on while on NimbleFi.
              </li>
              <li>
                <i>Device Information. </i> We collect information about the device you are using, such as hardware
                model, operating system, application version number, and browser.
              </li>
              <li>
                <i>Mobile Device Information. </i> In addition to the Device Information listed above, when you access
                NimbleFi via a browser on your mobile device, we collect and aggregate information about whether you are
                accessing NimbleFi via a mobile device or tablet, device type, and carrier.
              </li>
              <li>
                <i>Location Information. </i> We collect location information from NimbleFi visitors on a city-regional
                basis.
              </li>
            </ul>
            <p>
              <i>Third Party Information</i>
            </p>
            <p>
              In some cases, we may receive certain Personally Identifiable Information from you about a third party.
              For example, as part of our registration process, you may provide the email address of another individual
              you would like to add as a signer to your checking account. If you submit any Personally Identifiable
              Information about another individual to us, you are responsible for making sure that you have the
              authority to do so and to allow us to use their Personally Identifiable Information in accordance with
              this Privacy Policy.
            </p>
            <p>
              {' '}
              <i>Information from Other Sources</i>
            </p>
            <p>
              When you apply for a checking account through NimbleFi, we may obtain Personally Identifiable Information
              about you from other sources to verify the information you provided in your application. We may also
              collect information about you from other sources as part of conducting customer screenings in compliance
              with certain financial regulations.
            </p>
            <h3 id="title2-1-1">How We Use Your Personally Identifiable Information</h3>
            <p>
              We use the Personally Identifiable Information we collect to provide the services to you, to improve
              NimbleFi, and to protect our legal rights. In addition, we may use the Personally Identifiable Information
              we collect to:
            </p>
            <ul>
              <li>Process your account application;</li>
              <li>Verify your identity (authenticate your driver’s license to the photograph you upload);</li>
              <li>
                Detect and prevent fraud, identity theft and other risks to you, your banking institution, or SwitchTech
                (e.g., name screening in compliance with financial regulations);
              </li>
              <li>Contact you regarding our products and services that we feel may be of interest to you;</li>
              <li>Communicate with you about NimbleFi or to inform you of any changes to NimbleFi;</li>
              <li>Provide support;</li>
              <li>Maintain and improve NimbleFi;</li>
              <li>Defend our legal rights and the rights of others;</li>
              <li>Efficiently maintain our business; and</li>
              <li>Comply with applicable law.</li>
            </ul>
          </section>
          <section className="privacy-policy-section">
            <h2 id="title2-2">How We Share Your Personally Identifiable Information</h2>
            <p>We may share the information that we collect about you in the following ways:</p>
            <ul>
              <li>With your banking institution;</li>
              <li>With third-party name screening and identity verification providers;</li>
              <li>
                With service providers who perform data or platform-related services on our behalf (e.g., email,
                hosting, maintenance, backup, analysis, etc.). Any such service providers will be under an obligation to
                us to maintain the confidentiality of your Personally Identifiable Information;
              </li>
              <li>To service providers to prepare, deploy and analyse advertising content;</li>
              <li>To the extent that we are required to do so by law;</li>
              <li>In connection with any legal proceedings or prospective legal proceedings;</li>
              <li>
                To establish, exercise, or defend our legal rights, including providing information to others for the
                purposes of fraud prevention;
              </li>
              <li>
                To any person who we reasonably believe may apply to a court or other competent authority for disclosure
                of that Personally Identifiable Information where, in our reasonable opinion, such court or authority
                would be reasonably likely to order disclosure of that Personally Identifiable Information;
              </li>
              <li>To any other person or entity as part of any business or asset sale; and</li>
              <li>To any other person or entity where you consent to the disclosure.</li>
            </ul>
          </section>
          <section className="privacy-policy-section">
            <h2 id="title2-3">Cookies and Other Tracking Technologies</h2>
            <h3 id="title2-3-1">How We Use Cookies</h3>
            <p>
              Like many other companies, we use cookies and other tracking technologies (such as pixels and web beacons)
              (collectively, “Cookies”). “Cookies” are small files of information that are stored by your web browser
              software on your computer hard drive, mobile or other devices (e.g., smartphones or tablets).
            </p>
            <p>We may use Cookies to:</p>
            <ul>
              <li>Estimate audience size and usage patterns;</li>
              <li>
                Understand and save your preferences for future visits, allowing us to customize NimbleFi to your
                individual needs;
              </li>
              <li>Advertise new content and services that relate to your interests;</li>
              <li>Keep track of advertisements and search engine results;</li>
              <li>
                Compile aggregate data about site traffic and site interactions to resolve issues and offer better site
                experiences and tools in the future; and
              </li>
              <li>Recognise when you return to NimbleFi.</li>
            </ul>
            <p>
              We set some Cookies ourselves and others are set by other entities. We use Cookies set by other entities
              to provide us with useful information to help us improve NimbleFi, to conduct advertising, and to analyse
              the effectiveness of advertising.
            </p>
            <h3 id="title2-3-2">How You Can Opt-Out of Cookies</h3>
            <i>Browser Settings</i>
            <p>
              Cookies can be blocked by changing your Internet browser settings to refuse all or some Cookies. If you
              choose to block all Cookies (including essential Cookies) you may not be able to access all or parts of
              NimbleFi.
            </p>
            <p>You can find out more about Cookies and how to manage them by visiting</p>
            <p>
              <a target="_blank" href="www.AboutCookies.org" rel="noopener noreferrer">
                www.AboutCookies.org
              </a>{' '}
              or{' '}
              <a target="_blank" href="www.AllAboutCookies.org." rel="noopener noreferrer">
                www.AllAboutCookies.org.
              </a>
            </p>
            <i>Platform Controls </i>
            <p>
              By way of example, you can opt out of Cookies set by some well-known entities by following the
              instructions found at these links:
            </p>
            <ul>
              <li>
                Adobe:{' '}
                <a target="_blank" href="https://www.adobe.com/privacy/opt-out.html" rel="noopener noreferrer">
                  https://www.adobe.com/privacy/opt-out.html{' '}
                </a>
              </li>
              <li>
                Google:{' '}
                <a target="_blank" href="https://adssettings.google.com" rel="noopener noreferrer">
                  https://adssettings.google.com
                </a>{' '}
              </li>
              <li>
                Facebook:{' '}
                <a target="_blank" href="https://www.facebook.com/about/ads" rel="noopener noreferrer">
                  https://www.facebook.com/about/ads
                </a>
              </li>
              <li>
                LinkedIn:{' '}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </a>
              </li>
              <li>
                Microsoft:{' '}
                <a target="_blank" href="https://account.microsoft.com/privacy/ad-settings/" rel="noopener noreferrer">
                  https://account.microsoft.com/privacy/ad-settings/{' '}
                </a>
              </li>
              <li>
                Twitter:{' '}
                <a target="_blank" href="https://twitter.com/personalization" rel="noopener noreferrer">
                  https://twitter.com/personalization
                </a>
              </li>
              <li>
                Yahoo:{' '}
                <a
                  target="_blank"
                  href="https://policies.yahoo.com/xa/en/yahoo/privacy/topics/opt-outfaq/"
                  rel="noopener noreferrer"
                >
                  https://policies.yahoo.com/xa/en/yahoo/privacy/topics/opt-outfaq/
                </a>
              </li>
            </ul>
            <i>Advertising Industry Resources</i>
            <p>
              You can understand which entities have currently enabled Cookies for your browser or mobile device and how
              to opt-out of some of those Cookies by accessing the{' '}
              <a target="_blank" href="https://optout.networkadvertising.org/?c=1#!%2F" rel="noopener noreferrer">
                Network Advertising Initiative’s website
              </a>
              or the{' '}
              <a target="_blank" href="optout.aboutads.info/#!/" rel="noopener noreferrer">
                Digital Advertising Alliance’s website.
              </a>
              For more information on mobile specific opt-out choices, visit{' '}
              <a target="_blank" href="www.networkadvertising.org/mobile-choices" rel="noopener noreferrer">
                the Network Advertising Initiative’s Mobile Choices website.
              </a>
            </p>
            <p>
              Please note that these opt-out mechanisms are specific to the device or browser on which they are
              exercised. Therefore, you will need to opt out on every browser and device that you use.
            </p>
          </section>
          <section className="privacy-policy-section">
            <h2 id="title3">Third Party Processors</h2>
            <p>
              To ensure that your Personally Identifiable Information receives an adequate level of protection, we have
              put in place appropriate procedures with the service providers we share it with to ensure that it is
              treated consistent with applicable data security and privacy laws. For example, we use DocuSign, Inc. to
              allow you to electronically sign your checking account agreement. Your use of third-party services
              available through NimbleFi, including DocuSign, is subject to such third party’s privacy policy.
              DocuSign’s privacy policy is available{' '}
              <a target="_blank" href="https://www.docusign.com/company/privacy-policy" rel="noopener noreferrer">
                here.
              </a>
            </p>
            <h3 id="title3-1-2">Google Analytics</h3>
            <p>
              We use Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses Cookies or
              other tracking technologies to help us analyse how users interact with NimbleFi, compile reports on their
              activity, and provide other services related to their activity and usage. The technologies used by Google
              may collect information such as your IP address, time of visit, whether you are a returning visitor, and
              any referring website. The technologies used by Google Analytics do not gather information that personally
              identifies you. The information generated by Google Analytics will be transmitted to and stored by Google
              and will be subject to Google’s{' '}
              <a target="_blank" href="https://policies.google.com/privacy?hl=en" rel="noopener noreferrer">
                privacy policies.
              </a>
              To learn more about Google’s partner services and to learn how to opt-out of tracking of analytics by
              Google, click{' '}
              <a
                target="_blank"
                href="https://policies.google.com/technologies/partner-sites"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
            <h3 id="title3-1-2">Google reCAPTCHA</h3>
            <p>
              We use Google reCAPTCHA, a free service provided by Google, Inc., to protect NimbleFi from spam and abuse.
              Google reCAPTCHA uses advanced risk analysis techniques to decipher humans and bots. Google reCAPTCHA
              works differently depending on what version is deployed. For example, you may be asked to check a box
              indicating that you are not a robot or Google reCAPTCHA may detect abusive traffic without user
              interaction. Google reCAPTCHA works by transmitting certain types of information to Google, such as the
              referrer URL, IP address, visitor behavior, operating system information, browser and length of the visit,
              cookies, and mouse movements. Your use of Google reCAPTCHA is subject to Google’s{' '}
              <a target="_blank" href="https://policies.google.com/privacy" rel="noopener noreferrer">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a target="_blank" href="https://policies.google.com/terms" rel="noopener noreferrer">
                Terms of Use.
              </a>{' '}
              More information as to Google reCAPTCHA and how it works is available{' '}
              <a target="_blank" href="https://developers.google.com/recaptcha" rel="noopener noreferrer">
                here.
              </a>
            </p>
            <h3 id="title3-1-3">“Do Not Track” Signals</h3>
            <p>
              Some internet browsers incorporate a “Do Not Track” feature that signals to websites you visit that you do
              not want to have your online activity tracked. Given that there is not a uniform way that browsers
              communicate the “Do Not Track” signal, NimbleFi does not currently interpret, respond to or alter its
              practices when it receives “Do Not Track” signals.
            </p>
          </section>
          <section className="privacy-policy-section">
            <h2 id="title3-2">Choices About Your Personally Identifiable Information</h2>
            <h3 id="title3-2-1">Review and Request Changes to Your Personally Identifiable Information</h3>
            <p>
              You may use your account to access, correct, or view certain Personally Identifiable Information we have
              collected and which is associated with your NimbleFi account. To review or request changes to any of your
              Personally Identifiable Information, please contact us at{' '}
              <a href="mailto:support@nimblefi.com">support@nimblefi.com.</a>
            </p>
            <h3 id="title3-2-2">Marketing Communications</h3>
            <p>
              To unsubscribe from our marketing emails, please click the unsubscribe link included in the footer of our
              emails. You also may submit a request to us at{' '}
              <a href="mailto:support@nimblefi.com">support@nimblefi.com.</a>{' '}
            </p>
            <h3 title="title3-2-3">Security</h3>
            <p>
              We maintain commercially reasonable security measures to protect the Personally Identifiable Information
              we collect and store from loss, misuse, destruction, or unauthorised access. However, no security measure
              or modality of data transmission over the Internet is 100% secure. Although we strive to use commercially
              acceptable means to protect your Personally Identifiable Information, we cannot guarantee absolute
              security.
            </p>
            <h3 title="3-2-4">Third Party Links</h3>
            <p>
              NimbleFi may contain links that will let you leave NimbleFi and access another website. Linked websites
              are not under our control. This Privacy Policy applies solely to Personally Identifiable Information that
              is acquired by us on NimbleFi. We accept no responsibility or liability for these other websites.
            </p>
            <h3 title="3-2-5">Children’s Privacy</h3>
            <p>
              NimbleFi is not intended for children under 13 years of age. We do not knowingly collect, use, or disclose
              personal information from children under 13.
            </p>
            <h3 title="3-2-6">How to Contact Us</h3>
            <p>
              To contact us for questions or concerns about our privacy policies or practices please email us at
              <a href="mailto:support@nimblefi.com">support@nimblefi.com.</a>
            </p>
          </section>
        </div>
      </Document>
    </div>
  )
}
