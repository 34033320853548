import React from 'react'
import './AmountForm.sass'

interface IProductDetails {
  apy: string
  interestRate: string
  category?: string
}
/**
 * ProductDetails
 *
 * @param props IProductDetails
 */

export const ProductDetails: React.FC<IProductDetails> = (props: IProductDetails) => {
  return (
    <div>
      {props.category === 'CD' ? (
        <div className="product-details">
          <div>
            <label>Interest Rate</label>
            <span>{props.interestRate}%</span>
          </div>
          <div>
            <label>APY</label>
            <span>{props.apy}%</span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
