import React, { useContext } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Onboarding, NoMatch, Header, Footer } from './components'
import { ConfirmEmail } from './components/EmailConfirmation/ConfirmEmail'
import { FABHelpButton } from './components/FABHelpButton/FABHelpButton'
import './App.sass'
import { InstitutionContext } from './store'
import { Loading } from './components/Common/Loading/Loading'
import { useLoading } from './store/LoadingContext'
import { UrlContext } from './store/UrlContext'
import LocationHelper from './utils/LocationHelper'

const App: React.FC = () => {
  const institution = useContext(InstitutionContext)
  const { currentStep } = useContext(UrlContext)
  const { loading } = useLoading()
  const location = useLocation()

  React.useEffect(() => {
    document.title = LocationHelper.getLocationTitle(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className={loading ? 'App is-loading' : 'App'} data-testid="app">
      {/* tslint:disable-next-line: max-line-length */}
      <main
        className={`main step-${currentStep}`}
        style={{
          backgroundImage: institution?.backgroundImageUri?.default
            ? `url(${institution?.backgroundImageUri?.default})`
            : '',
        }}
        data-testid="main"
      >
        <Loading fullPage={true} active={loading} />

        <Switch>
          {/* Temporary redirect all to onboarding */}
          <Route exact={true} path="/">
            <Redirect to="/onboarding" />
          </Route>
          <Route path="/onboarding">
            <Header />
            <Onboarding />
            <Footer />
            <FABHelpButton
              show={
                currentStep === 'terms-and-conditions' ||
                currentStep === 'capture-documents' ||
                currentStep === 'selfie' ||
                currentStep === 'front' ||
                currentStep === 'back'
                  ? false
                  : true
              }
            />
          </Route>
          <Route path="/email-verification/:signerId/:verificationId">
            <Header />
            <ConfirmEmail />
            <Footer />
          </Route>
          <Route component={NoMatch} />
        </Switch>
      </main>
    </div>
  )
}

export default App
