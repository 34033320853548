import React from 'react'
import { PhotoPreview } from '../PhotoPreview/PhotoPreview'
import { Button } from '../../Common/Button/Button'
import './photo-preview.sass'
import { RefreshCcw, Check } from 'react-feather'

interface IPreview {
  imageData: string
  alt: string
  loading: boolean
  onRepeat(event: React.MouseEvent<HTMLButtonElement>): void
  onContinue(event: React.MouseEvent<HTMLButtonElement>): void
}

/* Simplify Preview Image and Restart Photo Button */
export const PhotoPreviewWithControls = (props: IPreview) => {
  return (
    <div className="preview-container">
      <div className="photo-preview">
        <PhotoPreview imageData={props.imageData} alt={props.alt} />
        <div className="action-buttons">
          <Button onClick={props.onRepeat} data-testid="RestartPhoto" className="is-pill restart">
            <RefreshCcw />
            Repeat Photo
          </Button>
          <Button
            onClick={props.onContinue}
            disabled={props.loading || !props.imageData}
            className={'is-green is-pill'}
          >
            <Check />
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

PhotoPreviewWithControls.defaultProps = { loading: false }
