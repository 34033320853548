import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { BSAForm } from '../../Forms/BSAForm/BSAForm'
import { log } from '../../../services'
import { LoadingContext } from '../../../store/LoadingContext'
import { IBSAQuestionnaire } from '../../../store/BsaContext'

import { useBsa } from '../../../store'

interface IStepBSAQuestionnaire {
  className?: string
  style?: React.CSSProperties
}
export const StepBSAQuestionnaire: React.FC<IStepBSAQuestionnaire> = (props) => {
  const history = useHistory()
  const { setLoading } = useContext(LoadingContext)
  const { bsa, updateBsa } = useBsa()

  const handleSubmit = async (data: IBSAQuestionnaire) => {
    setLoading(true)
    log.info(JSON.stringify(data), 'StepBSAQuestionnaire')
    await updateBsa(data)
    setLoading(false)

    history.push('/onboarding/invite-signers')
  }

  return (
    <div data-testid="step-bsa-questionnaire" className={'ni-step-bsa ni-test'} style={props.style}>
      <h3>Almost there...</h3>
      <h1>Please complete our questionnaire </h1>
      <BSAForm onSubmit={handleSubmit} defaultValues={bsa.results} />
    </div>
  )
}
