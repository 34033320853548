import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { log } from '../../../services'
import InstitutionService from '../../../services/Institution'
import './StepChooseProducts.sass'
import { ProspectContext, InstitutionContext } from '../../../store'
import { ProductSummary } from './ProductSummary'
import { ProductDetail } from './ProductDetail'
import { LoadingContext } from '../../../store/LoadingContext'
// import savingsIcon from './savingsIcon.svg'
// import savingsIconActive from './savingsIconActive.svg'
import checkingIcon from './checkingIcon.svg'
import checkingIconActive from './checkingIconActive.svg'
import cdIcon from './cdIcon.svg'
import cdIconActive from './cdIconActive.svg'
import { IProduct } from '../../../store/reducers/ProspectReducer'
import loansIcon from './loansIcon.svg'
import loansIconActive from './loansIconActive.svg'
import Config from '../../../services/Config'
import cardsIcon from './cardsIcon.svg'
import cardsIconActive from './cardsIconActive.svg'

interface IStepChooseProducts {
  className?: string
  style?: React.CSSProperties
}

/**
 * StepChooseProducts
 *
 * @param props IStepChooseProducts
 */
export const StepChooseProducts: React.FC<IStepChooseProducts> = (props: IStepChooseProducts) => {
  const history = useHistory()
  const institution = useContext(InstitutionContext)
  const { addProduct } = useContext(ProspectContext)

  const [products, setProducts] = useState<IProduct[]>([])
  const [activeTab, setActiveTab] = useState('CHECKING')

  const productsFilteredByCategory = () =>
    products.filter((product: IProduct) => {
      switch (product.category) {
        case 'LOAN':
          return (
            product.category === activeTab && Config.featureConfig.loans.products.find((name) => name === product.name)
          )
        case 'CARD':
          return (
            product.category === activeTab && Config.featureConfig.cards.products.find((name) => name === product.name)
          )
        default:
          return product.category === activeTab
      }
    })

  const { setLoading } = useContext(LoadingContext)
  const { prospect } = useContext(ProspectContext)
  const [isProductDetailVisible, toggleProductDetailView] = useState(false)
  const [currentlyShowingProduct, setCurrentlyShowingProduct] = useState({} as IProduct)

  // ISSUE-33 Begin
  const { updateInitialDeposit } = useContext(ProspectContext)

  /**
   * This is added to prevent the the additonal features page fromshowing up when there is none.
   * It implements the required continuation based on the implementation in step choose product options
   * @param showingProduct
   */
  const navigateToNextPage = (showingProduct: IProduct) => {
    const productOptions = showingProduct.options.filter((option) => option.category === 'product_feature')
    if (productOptions.length >= 1) {
      history.push('/onboarding/choose-product-options')
    } else {
      history.push('/onboarding/invite-signers')
    }
  }

  const chooseAmountFlow = async (showingProduct: IProduct) => {
    await updateInitialDeposit(0)
    if (prospect.inviteeToken === undefined || prospect.inviteeToken === null) {
      navigateToNextPage(showingProduct)
    } else {
      history.push('/onboarding/success')
    }
  }
  // ISSUE-33 End

  const handleSubmit = async (productId: string, productName: string) => {
    log.info(`product selected and submitted`, 'StepChooseProducts')
    const showingProduct = products.find((product: IProduct) => product.id === productId)
    if (showingProduct) {
      await addProduct(showingProduct)
      if (showingProduct.category === 'CD') {
        navigateToNextPage(showingProduct)
      } else if (Config.featureConfig.loans.enabled && showingProduct.category === 'CARD') {
        navigateToNextPage(showingProduct)
      } else if (Config.featureConfig.loans.enabled && showingProduct.category === 'LOAN') {
        navigateToNextPage(showingProduct)
      } else {
        // ISSUE-33 - Hide choose-amount
        // history.push('/onboarding/choose-amount')
        chooseAmountFlow(showingProduct)
      }
    }
  }

  const showProduct = (productId: string) => {
    toggleProductDetailView(true)
    const showingProduct = products.find((product: IProduct) => product.id === productId)
    if (showingProduct) {
      setCurrentlyShowingProduct(showingProduct)
    }
  }

  const onTabClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const id = event.currentTarget.getAttribute('data-id') || ''
    log.info(id, 'onTabClick')

    setActiveTab(id)
    return
  }

  useEffect(() => {
    setLoading(true)
    const isOnboardingForInvitee = prospect.signerId
    if (isOnboardingForInvitee !== undefined) {
      // if it is invitee who use the app, then skip BSA, ChooseProductsStep and InviteStep
      setLoading(false)
      history.push('/onboarding/success')
    } else {
      const fetchProducts = async (id: string) => {
        const items = await InstitutionService.getProducts(id)

        setProducts(items)
        return items
      }

      if (institution) {
        fetchProducts(institution.id)
      }
      setLoading(false)
    }
  }, [history, institution, setLoading, prospect])

  return (
    <div data-testid="StepChooseProducts" className={`ni-step-choose-products ${props.className}`} style={props.style}>
      <h1>What type of Account are you interested in?</h1>

      {!isProductDetailVisible && (
        <div className="product-tabs">
          <div
            data-id={'CHECKING'}
            data-testid="StepTabCheckingsAccount"
            className={`tab ${activeTab === 'CHECKING' ? 'active' : ''}`}
            onClick={onTabClick}
          >
            <img
              data-testid="StepTabCheckingsAccountImage"
              src={activeTab === 'CHECKING' ? checkingIconActive : checkingIcon}
              alt="checkings account icon"
            />
            <span>Checkings Accounts</span>
          </div>
          {Config.featureConfig.cards.enabled ? (
            <div
              data-id={'CARD'}
              data-testid="StepTabCardsAccount"
              className={`tab ${activeTab === 'CARD' ? 'active' : ''}`}
              onClick={onTabClick}
            >
              <img
                data-testid="StepTabCardsAccountImage"
                src={activeTab === 'CARD' ? cardsIconActive : cardsIcon}
                alt="cards account icon"
                className="cardIcon"
              />
              <span>Cards</span>
            </div>
          ) : (
            ' '
          )}
          {Config.featureConfig.loans.enabled ? (
            <div
              data-id={'LOAN'}
              data-testid="StepTabLoansAccount"
              className={`tab ${activeTab === 'LOAN' ? 'active' : ''}`}
              onClick={onTabClick}
            >
              <img
                data-testid="StepTabLoansAccountImage"
                src={activeTab === 'LOAN' ? loansIconActive : loansIcon}
                alt="loans account icon"
                className="loanIcon"
              />
              <span>Loans</span>
            </div>
          ) : (
            ' '
          )}
          {products.find((product, i) => product.category === 'CD') && (
            <div
              data-id={'CD'}
              data-testid="StepTabCDSAccount"
              className={`tab ${activeTab === 'CD' ? 'active' : ''}`}
              onClick={onTabClick}
            >
              <img
                data-testid="StepTabCDSAccountImage"
                src={activeTab === 'CD' ? cdIconActive : cdIcon}
                alt="savings account icon"
              />
              <span>Certificates of Deposit</span>
            </div>
          )}
        </div>
      )}

      <div className="products-list">
        {isProductDetailVisible ? (
          <ProductDetail
            product={currentlyShowingProduct}
            toggleProductDetailView={toggleProductDetailView}
            submitProduct={handleSubmit}
          />
        ) : (
          <>
            {productsFilteredByCategory().length > 0 ? (
              productsFilteredByCategory().map((product: IProduct) => (
                <ProductSummary
                  product={product}
                  key={product.id}
                  submitProduct={handleSubmit}
                  showProduct={showProduct}
                />
              ))
            ) : (
              <div className="product">
                <div className="no-product">
                  <h3>No products available</h3>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
