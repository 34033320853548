import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Lottie } from '@alfonmga/react-lottie-light-ts'
import animationData from '../../../animations/camera.json'
import { defaultOptions } from '../../../animations/options'
import { log } from '../../../services'
import { ProspectContext, InstitutionContext } from '../../../store'
import { uploadDocument, useDocumentState, useDocumentDispatch } from '../../../store/DocumentsContext'

import { PhotoCapture, PhotoPreviewWithControls } from '../..'
import { LoadingContext } from '../../../store/LoadingContext'
import { AlertCircle } from 'react-feather'

interface IStepSelfie {
  className?: string
  style?: React.CSSProperties
}

export const StepSelfie: React.FC<IStepSelfie> = (props: IStepSelfie) => {
  const history = useHistory()
  const { setLoading } = useContext(LoadingContext)
  const institution = useContext(InstitutionContext)
  const FACING_MODE_USER = 'user'
  const FACING_MODE_ENVIRONMENT = 'environment'
  // documents
  const { documents, status, error } = useDocumentState()
  const { front, back } = documents
  const dispatch = useDocumentDispatch()

  const { populateProspectWithFields } = useContext(ProspectContext)

  const [photo, setPhoto] = useState('')
  const [cameraState, setCameraState] = React.useState('closed')

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER)
  const [allowFlip, setAllowFlip] = useState(false)

  const animationOptions = {
    ...defaultOptions,
    animationData,
  }

  useEffect(() => {
    const goToNextStep = () => {
      log.info('continue to next step', 'cameraPhoto')
      dispatch({ type: 'cancel' })
      history.push('/onboarding/ssn')
    }

    if (status === 'success') {
      return goToNextStep()
    }

    if (status === 'failure') {
      setCameraState('closed')
    }

    // check if more than 1 cam is present
    navigator.mediaDevices
      .enumerateDevices()
      .then((mediaDeviceInfos) => {
        const videoMediaDevices = mediaDeviceInfos.filter((mdi) => mdi.kind === 'videoinput')
        if (videoMediaDevices.length > 1) {
          setAllowFlip(true)
        }
      })
      .catch((errorException) => {
        setAllowFlip(false)
        log.info('Error getting num of Cameras', errorException.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const handleTakePhoto = (photoDataUri: string) => {
    log.info('selfie done', 'StepSelfie')
    setPhoto(photoDataUri)
    setCameraState('preview-active')
  }

  const handleContinueSelfie = async () => {
    setLoading(true)
    await uploadDocument(dispatch, photo, 'selfie', institution!.id)
    log.info('Selfie sent', 'StepSelfie')

    await populateProspectWithFields(front!.id, back !== null ? back!.id : '')
    setLoading(false)
  }

  const handleRestartPhoto = () => {
    log.info('remove selfie', 'StepSelfie')
    setPhoto('')
    setCameraState('camera-active')
  }

  const handleOpenCamera = () => {
    log.info('open camera', 'StepSelfie')
    setCameraState('camera-active')
  }

  const handleFlipCamera = () => {
    if (facingMode === FACING_MODE_USER) {
      setFacingMode(FACING_MODE_ENVIRONMENT)
    } else {
      setFacingMode(FACING_MODE_USER)
    }
    log.info('flipping camera', facingMode)
  }

  return (
    <div data-testid="step-selfie" className={`step-selfie camera-step text-center ${cameraState}`}>
      <PhotoCapture
        onTakePhoto={handleTakePhoto}
        onFlipCamera={handleFlipCamera}
        allowFlip={allowFlip}
        title={'Position your face in the middle of the screen. Make sure your face visible.'}
        facingMode={facingMode}
      />

      <div className="intro-text">
        <h3>Almost there.</h3>
        <h2>Now let's take a photo to confirm your identity</h2>
        <div className="camera-animation">
          <Lottie config={animationOptions} />
        </div>
        <button onClick={handleOpenCamera} className="button is-pill is-green">
          Open Camera
        </button>
      </div>

      <PhotoPreviewWithControls
        alt={'Selfie Preview'}
        imageData={photo}
        onRepeat={handleRestartPhoto}
        onContinue={handleContinueSelfie}
        loading={status === 'create'}
      />

      {error && (
        <div role="alert" className="alert toast is-error  u-margin-top-xl">
          <AlertCircle /> {error}
        </div>
      )}
    </div>
  )
}
