import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import NimbleRouter from './NimbleRouter'
import { LoadingProvider } from './store/LoadingContext'
import App from './App'
import { NoMatch, Error as ErrorPage } from './components'
import { UrlProvider } from './store/UrlContext'
import { ErrorBoundary } from '@sentry/react'

const Main: React.FC = () => {
  return (
    <Router>
      <ErrorBoundary showDialog={false} fallback={ErrorPage}>
        <Switch>
          <Route exact={true} path="/404" component={NoMatch} />
          <Route exact={true} path="/">
            <Redirect to="/nimblefi.com" />
          </Route>
          <Route path="/:clientDomain">
            <NimbleRouter>
              <LoadingProvider>
                <UrlProvider>
                  <App />
                </UrlProvider>
              </LoadingProvider>
            </NimbleRouter>
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}

export default Main
