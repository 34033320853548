import React, { useContext, useEffect, useState } from 'react'
import { IFormRepeatableSigners } from '../../Forms/InvitesForm/RepeatableSigner/RepeatableSigner'
import { log } from '../../../services'
import { ProspectContext } from '../../../store'
import { useHistory } from 'react-router-dom'
import './StepInvites.sass'
import { SIGNER_ROLES } from './constants'
import { AlertMessage, InvitesForm } from '../..'

interface IStepInvites {
  style?: React.CSSProperties
  title?: string
  maxInvitees?: number
}

/**
 * Step for Inviting new Signers
 *
 * @param props IStepInvites
 */
export const StepInvites: React.FC<IStepInvites> = (props: IStepInvites) => {
  const history = useHistory()
  const { prospect, setInvitees } = useContext(ProspectContext)

  const { invitees = [], selectedProductName = '' } = prospect
  const [showMessage, setShowMessage] = useState(invitees.some((i) => i.role === SIGNER_ROLES.PRIMARY))
  useEffect(() => {
    document.title = props.title || ''
  }, [props.title])

  const onChangePrimaryRole = (isPrimaryChecked: boolean) => {
    setShowMessage(isPrimaryChecked)
  }

  /* go to the next step and don't invite anyone */
  const handleSkip = () => {
    log.info('Skipped', 'StepInvites')
    goToNextStep()
  }

  const handleBack = () => {
    history.goBack()
  }

  const goToNextStep = () => {
    prospect?.selectedProduct?.category === 'CARD' ? 
      history.push('/onboarding/personal-mortgage-loan-configuration') : prospect.selectedProduct?.category === 'LOAN' ? 
        history.push('/onboarding/personal-mortgage-loan-configuration')
      : history.push('/onboarding/success')
  }

  /** after submit, post to server and get response */
  const onSubmitForm = async (data: IFormRepeatableSigners) => {
    log.info(JSON.stringify(data), 'Submit on StepInvites!')

    setInvitees(data.signers)
    goToNextStep()
  }

  return (
    <div data-testid="StepInvites" className={'ni-step-signers'} style={props.style}>
      {showMessage && (
        <AlertMessage title="You are changing the account ownership">
          By changing a signer to primary, you will automatically become a secondary account holder of this account. Are
          you sure?
        </AlertMessage>
      )}
      <h1>Do you wish to apply for a {selectedProductName} account with a co-applicant?</h1>
      <p className="hint">
        Just type the email of the person you wish to invite.
        <br />
        If you don’t wish to invite others simply click <strong>Sign and Finish</strong>.
      </p>

      <InvitesForm
        onChangePrimaryRole={onChangePrimaryRole}
        onSubmit={onSubmitForm}
        onSkip={handleSkip}
        onBack={handleBack}
        defaultInvites={invitees}
        loading={false}
        maxInvitees={props.maxInvitees}
        disallowedEmails={prospect?.signer?.email ? [prospect.signer.email] : undefined}
      />
    </div>
  )
}
