import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingContext } from '../../../store/LoadingContext'
import { log } from '../../../services'
import './StepOnboardOthers.sass'
import SignerService from '../../../services/Signer'
import { InstitutionContext, ProspectContext } from '../../../store'
import { Button } from '../..'

interface IStepOnboardOthers {
  className?: string
  style?: React.CSSProperties
  title?: string
}
export interface IInviteesList {
  id: string
  email: string
  signed: boolean
}
/**
 * Step Onboard Others
 *
 * @param props IStepOnboardOthers
 */
export const StepOnboardOthers: React.FC<IStepOnboardOthers> = (props: IStepOnboardOthers) => {
  const institution = useContext(InstitutionContext)
  const { prospect } = useContext(ProspectContext)
  const history = useHistory()
  const parsedSigner = prospect.invitedBy
  const institutionDomain = institution ? institution.domain : window.location.pathname.split('/')[1]
  const token = prospect.securityToken
  const accountRequestId = prospect.accountRequestId

  const { loading, setLoading } = useContext(LoadingContext)
  const [inviteesList, setinviteesList] = useState<IInviteesList[]>([])

  const getAllSigners = async () => {
    try {
      const inviteesStrings = sessionStorage.getItem('invitees')
      const parsedInvitees = inviteesStrings !== null ? JSON.parse(inviteesStrings!) : []

      log.info('fetching signers status', 'getAllSigners')

      let inviteeSigners: IInviteesList[]
      inviteeSigners = await Promise.all(
        parsedInvitees.map(async (invitee: IInviteesList) => {
          const signerToken = (prospect.tokens || []).find((el) => el.id === invitee.id)?.token || ''
          const signer = await SignerService.getSigner(signerToken, invitee.id)

          return {
            id: signer.id,
            name: signer.firstName,
            email: signer.email,
            signed: signer.firstName === null ? false : true,
          }
        })
      )
      setinviteesList(inviteeSigners)
      log.info('got list of signers', 'getAllSigners')

      return inviteeSigners
    } catch (error) {
      log.error(error, 'getAllSigners')
      return []
    }
  }

  React.useEffect(() => {
    const runAsyncFunctions = async () => {
      setLoading(true)

      const inviteeSigners = await getAllSigners()
      const shouldSkip = !Object.values(inviteeSigners).some((x) => x.signed !== true) || parsedSigner === undefined
      if (shouldSkip) {
        log.info('Skipping onboard step', 'StepOnboardOther')
        goToSuccess()
        return
      }
      setLoading(false)
    }
    runAsyncFunctions()

    return () => setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStartOnboading = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.getAttribute('data-id')
    const baseURL = '/onboarding/:account_request_id/signers/:signer_id?name=:invitedBy&token=:token'
    const inviteLink = baseURL
      .replace(':account_request_id', accountRequestId!)
      .replace(':signer_id', id!)
      .replace(':invitedBy', parsedSigner ? parsedSigner.firstName : 'Someone')
      .replace(':domain', institutionDomain)
      .replace(':token', token!)

    log.info(inviteLink, 'handleStartOnboading')
    history.push(inviteLink)
    return
  }

  const goToSuccess = () => {
    sessionStorage.clear()
    history.push('/onboarding/success')
    return
  }

  if (loading) {
    return <></>
  }
  return (
    <div data-testid="StepOnboardOthers" className={'ni-step-onboard-others ' + props.className} style={props.style}>
      <h3 data-testid="StepOnboardOthersTitle">Do you wish to onboard other applicants right now or later?</h3>
      <p>You can choose to onboard other co-applicants right now or invite them to join later </p>
      <ul className="applicants" data-testid="applicantsList">
        {inviteesList.map((signerInvitee: any) => (
          <li
            key={signerInvitee.id}
            className={signerInvitee.signed ? 'signed' : 'not-signed'}
            data-testid={parsedSigner!.email === signerInvitee.email ? `currentSigner` : `applicant-item`}
          >
            <p>{signerInvitee.signed ? signerInvitee.name : signerInvitee.email}</p>
            {signerInvitee.signed ? (
              <span>{signerInvitee.signed && 'Complete'}</span>
            ) : (
              <Button className={'is-pill'} data-id={signerInvitee.id} onClick={handleStartOnboading}>
                Start Now
              </Button>
            )}
          </li>
        ))}
      </ul>
      <div className="controls">
        <Button className={'is-pill is-green'} onClick={goToSuccess} data-testid="go-to-success">
          Send invite to pending co-applicants
        </Button>
      </div>
    </div>
  )
}
