import React from 'react'
import { useUID } from 'react-uid'
import { ErrorMessage, Label } from '../..'
import './Dropdown.sass'

type SelectProps = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
interface IProps extends SelectProps {
  label?: string
  name: string
  placeholder?: string
  className?: string
  errors?: any
  autoFocus?: boolean
  options?: string[]
  disabled?: boolean
  defaultValue?: string
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Dropdown = React.forwardRef<HTMLSelectElement, IProps>((props, ref) => {
  const uid = useUID()
  return (
    <div className={`form-group form-group-select ${props.className}`} data-testid={uid}>
      <ErrorMessage errors={props.errors} />
      <div className="form-fields">
        <select
          id={uid}
          className="form-select"
          name={props.name}
          data-testid={`select-${props.name}`}
          ref={ref}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        >
          {props.options ? (
            props.options.map((data) => {
              return (
                <option key={data} value={data}>
                  {data}
                </option>
              )
            })
          ) : (
            <option key={''} value={''}>
              ''
            </option>
          )}
        </select>
        {props.label && <Label for={uid}>{props.label}</Label>}
      </div>
    </div>
  )
})

Dropdown.defaultProps = {
  disabled: false,
  className: '',
}
