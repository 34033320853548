import CoreAPI from './CoreAPI'

export interface IPersonInfo {
  ssn: string
}
export interface IPersonInfoRequest {
  phoneNumber: string,
  firstName: string,
  lastName: string
}

class PersonInfo extends CoreAPI {

  public async getPersonInfo(personRequest: IPersonInfoRequest): Promise<IPersonInfo> {
    //remove special characters from phone Number
    personRequest.phoneNumber = personRequest.phoneNumber.replace('-', '').replace('(', '').replace(')', '')
    return await this.getRequest(`/person-social-info`, personRequest)
  }
}

export default new PersonInfo()