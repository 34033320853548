import CoreAPI from './CoreAPI'
import { AxiosError } from 'axios'

interface IDocument {
  content: string
  format: string
  institutionId: string
}

/* Logic for Documents in core api */
class Document extends CoreAPI {
  /**
   * Create a document on Nimble
   *
   * @param payload - body parameters of request
   */
  public async create(payload: IDocument) {
    return await this.postRequest(`/documents`, payload)
  }

  /**
   * Return a single error message
   *
   * @param error - axios error
   */
  public errorMessage(error: AxiosError) {
    if (error.response) {
      // Request made and server responded
      return error.response.data.message
    } else if (error.request) {
      // The request was made but no response was received
      return 'We were unable to upload your photo. Make sure you have an internet connection and try again.'
    } else {
      // Something happened in setting up the request that triggered an Error
      return error.message
    }
  }
}

export default new Document()
