import React from 'react'
import { useUID } from 'react-uid'
import { ErrorMessage } from '../..'
import '../Input/Input.sass'
import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  placeholder?: string
  defaultValue?: string
  className?: string
  errors?: any
  format: string
  mask?: string
  allowNegative?: boolean
  type?: 'text' | 'tel' | 'password'
  control: any // this is from react hook, don't know the type
  rules?: any // this is from react hook, don't know the type
}

/**
 * This is a controlled input with mask to use with React Form
 * It uses NumberFormat - https://github.com/s-yadav/react-number-format
 * @param props - IProps
 */
export const InputWithMask: React.FunctionComponent<IProps> = (props: IProps) => {
  const uid = useUID()
  return (
    <div className={`form-group ${props.className}`} data-testid={uid}>
      <ErrorMessage errors={props.errors} />
      <div className="form-fields">
        <Controller
          control={props.control}
          id={uid}
          data-testid={`input-${props.name}`}
          name={props.name}
          label={props.label}
          className={`form-input ${props.className}`}
          errors={props.errors}
          type={props.type}
          format={props.format}
          mask={props.mask}
          defaultValue={props.defaultValue}
          allowNegative={props.allowNegative}
          rules={props.rules}
          as={NumberFormat}
        />

        {props.label && (
          <label className="form-label" htmlFor={uid}>
            {props.label}
          </label>
        )}
      </div>
    </div>
  )
}

InputWithMask.defaultProps = {
  type: 'text',
  className: '',
}
