/* eslint-disable no-useless-computed-key */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  StepBackDocument,
  StepFrontDocument,
  StepSelfie,
  StepSuccess,
  StepPersonalInfo,
  StepChooseProducts,
  StepChooseProductOptions,
  StepChooseAmount,
  StepInstructions,
  StepInvites,
  StepBSAQuestionnaire,
  StepTermsAndConditions,
  StepCaptureDocuments,
  StepSignContract,
  StepOnboardOthers,
  StepOnboardingComplete,
  StepVerifyEmail,
  PrivacyPolicy,
  StepDisclosure,
  StepPhoneVerification,
  StepEmailEntry,
  StepConfirmId,
  StepSsn
} from '..'
import {
  ProspectProvider,
  DocumentsProvider,
  ConsentsProvider,
  BsaProvider,
  PhoneVerificationProvider,
  SsnProvider,
} from '../../store'
import './Onboarding.sass'
import { UrlStateManager } from './UrlStateManager'
import { ScrollRestoration } from '../ScrollRestoration/ScrollRestoration'
import { StepInstructionsInvitees } from '../Steps/StepInstructionsInvitees/StepInstructionsInvitees'
import { StepPersonalMortgageLoanQuestionnaire } from '../Steps/Personal/Loan/StepPersonalMortgageLoanQuestionnaire'
import { PersonalMortgageLoanProvider } from '../../store/Personal/Loan/PersonalMortgageLoanContext'

interface IOnboarding {
  style?: React.CSSProperties
}

/**
 * Onboarding starts here
 *
 * @param props IOnboarding
 */
export const Onboarding: React.FC<IOnboarding> = (props: IOnboarding) => {
  return (
    <div data-testid="onboarding" className="mainContainer_wrapper">
      <div className="mainContainer">
        <ProspectProvider>
          <DocumentsProvider>
            <ConsentsProvider>
              <BsaProvider>
                <PhoneVerificationProvider>
                  <SsnProvider>
                  <PersonalMortgageLoanProvider>
                    <UrlStateManager />
                    <ScrollRestoration />
                    <Switch>
                      <Route path="/onboarding/terms-and-conditions">
                        <StepTermsAndConditions />
                      </Route>
                      <Route path="/onboarding/phone-verification">
                        <StepPhoneVerification />
                      </Route>
                      <Route path="/onboarding/confirm-id">
                        <StepConfirmId />
                      </Route>
                      <Route path="/onboarding/email">
                        <StepEmailEntry />
                      </Route>
                      <Route path="/onboarding/disclosure">
                        <StepDisclosure />
                      </Route>
                      <Route path="/onboarding/capture-documents">
                        <StepCaptureDocuments />
                      </Route>
                      <Route path="/onboarding/front">
                        <StepFrontDocument />
                      </Route>
                      <Route path="/onboarding/back">
                        <StepBackDocument />
                      </Route>
                      <Route path="/onboarding/selfie">
                        <StepSelfie />
                      </Route>
                      <Route path="/onboarding/personal-info">
                        <StepPersonalInfo />
                      </Route>
                      <Route path="/onboarding/ssn">
                        <StepSsn />
                      </Route>
                      <Route path="/onboarding/choose-products">
                        <StepChooseProducts />
                      </Route>
                      <Route path="/onboarding/choose-product-options">
                        <StepChooseProductOptions />
                      </Route>
                      <Route path="/onboarding/personal-mortgage-loan-configuration">
                        <StepPersonalMortgageLoanQuestionnaire />
                      </Route>
                      <Route path="/onboarding/choose-amount">
                        <StepChooseAmount />
                      </Route>
                      <Route path="/onboarding/invite-signers">
                        <StepInvites maxInvitees={3} />
                      </Route>
                      <Route path="/onboarding/bsa-questionnaire">
                        <StepBSAQuestionnaire />
                      </Route>
                      <Route path="/onboarding/other-applicants">
                        <StepOnboardOthers />
                      </Route>
                      <Route path="/onboarding/verify-email">
                        <StepVerifyEmail />
                      </Route>
                      <Route path="/onboarding/success">
                        <StepSuccess />
                      </Route>
                      <Route path="/onboarding/sign-contract">
                        <StepSignContract />
                      </Route>
                      <Route path="/onboarding/:accountRequestId/signers/:signerId">
                        <StepInstructionsInvitees />
                      </Route>
                      <Route path="/onboarding/privacy-policy">
                        <PrivacyPolicy />
                      </Route>
                      <Route path="/onboarding/already-complete">
                        <StepOnboardingComplete />
                      </Route>
                      {/* if no route matches go to step 1 */}
                      <Route path="*">
                        <StepInstructions />
                      </Route>
                    </Switch>
                  </PersonalMortgageLoanProvider>
                  </SsnProvider>
                </PhoneVerificationProvider>
              </BsaProvider>
            </ConsentsProvider>
          </DocumentsProvider>
        </ProspectProvider>
      </div>
    </div>
  )
}
