import React from 'react'
import NumberFormat from 'react-number-format'
import { ErrorMessage } from '../..'
import '../Input/Input.sass'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  type?: string
  label?: string
  forwardRef: any // TODO: dont know what is this type
  placeholder?: string
  defaultValue?: number
  className?: string
  value?: number
  errors?: any
  prefix?: string
  allowNegative?: boolean
  isAllowed?: any
  isNumericString?: boolean
  onValueChange?: any
  min?: number
  thousandSeparator?: boolean
}

export const InputCurrency: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <div className={`form-group ${props.className}`}>
      {props.label && (
        <label className="form-label" style={{ order: 0 }}>
          {props.label}
        </label>
      )}
      <div className="form-fields">
        <NumberFormat
          value={props.value || props.defaultValue}
          name={props.name}
          label={props.label}
          className={props.className}
          data-testid={`input-${props.name}`}
          getInputRef={props.forwardRef}
          errors={props.errors}
          allowNegative={false}
          onValueChange={props.onValueChange}
          isNumericString={props.isNumericString}
          prefix={props.prefix}
          isAllowed={props.isAllowed}
          thousandSeparator={true}
        />
      </div>
      <ErrorMessage errors={props.errors} />
    </div>
  )
}

InputCurrency.defaultProps = {
  type: 'text',
  className: '',
}
