import React from 'react'
import './Label.sass'

interface IProps {
  for: string
  children?: React.ReactNode
}

export const Label: React.FC<IProps> = (props: IProps) => {
  return (
    <label className="form-label" htmlFor={props.for}>
      {props.children}
    </label>
  )
}
