import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './StepOnboardingComplete.sass'
import { ProspectContext, InstitutionContext } from '../../../store'
import { ButtonList } from '../../ButtonList/ButtonList'
import { log } from '../../../services'

interface IStepOnboardingComplete {
  className?: string
  style?: React.CSSProperties
  title?: string
}

/**
 * Step Onboading Complete
 *
 * @param props IStepOnboardingComplete
 */
export const StepOnboardingComplete: React.FC<IStepOnboardingComplete> = (props: IStepOnboardingComplete) => {
  const history = useHistory()
  const { prospect, resetProspect } = useContext(ProspectContext)
  const params = new URLSearchParams(window.location.search)
  const name = params.get('name')
  const institution = useContext(InstitutionContext)
  const handleRestart = () => {
    log.info('Restart Process', 'StepSuccess')
    resetProspect()
    history.push('/onboarding')
  }

  return (
    <div
      data-testid="StepOnboardingComplete"
      className={'ni-step-onboarding-complete ' + props.className}
      style={props.style}
    >
      <h3 data-testid="StepOnboardingCompleteTitle">Hello {name ? name : ''}</h3>
      <h2>It seems that you already completed your account request application!</h2>
      <p>
        If you didn’t apply or completed the onboarding process for a {prospect.selectedProductName} account please
        contact our team by clicking the “Need Help” Button.
      </p>

      <ButtonList handleRestart={handleRestart} institution={institution?.domain} className="completed" />
    </div>
  )
}
