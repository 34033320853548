import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { getPersonInfo, useSsnState, useSsnDispatch } from '../../../store/SsnContext'
import { ProspectContext } from '../../../store'
import { Loading } from '../../../components/Common/Loading/Loading'
import './StepSsn.sass'
import { SsnForm } from '../../Forms/SsnForm/SsnForm'

interface IStepSsn {
  className?: string
  style?: React.CSSProperties
}

export const StepSsn: React.FC<IStepSsn> = (props: IStepSsn) => {
  const history = useHistory()
  const { status, person } = useSsnState()
  const dispatch = useSsnDispatch()
  const { prospect, updateSigner } = useContext(ProspectContext)

  React.useEffect(() => {
    getPersonInfo(dispatch, {
      firstName: prospect.signer.firstName!,
      lastName: prospect.signer.lastName!,
      phoneNumber: prospect.signer.phoneNumber!,
    })
  }, [prospect.signer.firstName, prospect.signer.lastName, prospect.signer.phoneNumber, dispatch])

  const handleOnCompleted = async (ssn: string) => {
    updateSigner({ ...prospect.signer, ssn })
    history.push('/onboarding/personal-info')
  }
  if (status === 'loading') {
    return <Loading fullPage={true} active={true} width="300px" height="300px" />
  }

  return (
    <div data-testid="step-ssn" className={`text-center`}>
      <div className="max-wrapper">
        <h3>SSN Confirmation</h3>
        <h2>
          {person && person.ssn
            ? `Enter the last 4 digits of your social security number`
            : `Please enter your social security number`}
        </h2>
        <SsnForm person={person} onCompleted={handleOnCompleted} />
      </div>
    </div>
  )
}
