import React from 'react'
import { Lottie } from '@alfonmga/react-lottie-light-ts'
import animationData from '../../../animations/loading.json'
import { defaultOptions } from '../../../animations/options'

import './loading.sass'

// generic Loading component
// fullPage - means absolute positioning with 100vh height and 100wv width

interface ILoading {
  fullPage?: boolean
  active: boolean
  width?: string
  height?: string
  text?: string
}

export const Loading: React.FC<ILoading> = ({ width, height, text, fullPage, active }) => {
  if (!active) {
    return null
  }
  const animationOptions = {
    ...defaultOptions,
    width,
    height,
    animationData,
  }
  return (
    <div className={fullPage ? 'loading-cover' : 'loading-square'} data-testid="loading-test">
      <div style={{ width, height }}>
        <Lottie config={animationOptions} />
      </div>
      {text}
    </div>
  )
}

Loading.defaultProps = {
  text: 'Loading ...',
  width: '200px',
  height: '200px',
}
