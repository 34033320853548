import React from 'react'
import './button.sass'
import { ArrowRight } from 'react-feather'

export interface Ibutton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  block?: true
  className?: string
  loading?: boolean
  disabled?: boolean
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void
}

export const Button: React.FunctionComponent<Ibutton> = ({
  className,
  block,
  children,
  onClick,
  loading,
  disabled,
  ...shared
}) => {
  return (
    <button {...shared} disabled={disabled} onClick={onClick} className={`button ${className}`}>
      {loading && <ArrowRight className="loading" />}
      {!loading && children}
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
  className: '',
  loading: false,
  disabled: false,
}
