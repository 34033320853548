import React from 'react'
import SignerService from '../services/Signer'
import { log } from '../services'

export const useEmailVerificationStatus = (signerId: string, verificationId: string, token: string) => {
  const [status, setStatusPut] = React.useState('loading')
  const [error, setErrorPut] = React.useState(0)

  React.useEffect(() => {
    async function confirmEmail() {
      try {
        await SignerService.signerConfirmEmail(signerId, verificationId, token)
        setStatusPut('success')
      } catch (error) {
        log.error('StepConfirmEmail', error.response)
        setStatusPut(error.response.data.message)
        setErrorPut(error.response.status)
      }
    }
    confirmEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line no-restricted-globals
  return { status, error }
}
