import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, ErrorMessage } from '../../index'
import { Controller } from 'react-hook-form'
import './SsnForm.sass'
import { AlertCircle } from 'react-feather'
import CodeInput from '../../Common/CodeInput/CodeInput'
import { IPersonInfo } from '../../../services/PersonInfo'

const schema = yup.object().shape({
  hasPerson: yup.boolean(),
  ssn4: yup
    .string()
    .min(4, '4 digits required')
    .when("hasPerson", {
      is: true,
      then: yup.string().required('last 4 digits of your SSN is required')
      .min(4, '4 digits required'),
    }),
    
  ssn: yup
    .string()
    .min(9, '9-digit SSN required')
    .when("hasPerson", {
      is: false,
      then: yup.string().required('SSN is required')
      .min(9, '9-digit SSN required'),
    }),
})

export interface IPhoneCodeCheckDetails {
  hasPerson: boolean,
  ssn: string,
  ssn4: string,
}

interface ISsnForm {
  style?: React.CSSProperties
  className?: string
  person: IPersonInfo | null
  onCompleted: (ssn: string) => void
}

export const SsnForm: React.FC<ISsnForm> = (props: ISsnForm) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { control, handleSubmit, errors, setValue } = useForm<IPhoneCodeCheckDetails>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
      if(props.person && props.person.ssn){
        setValue('hasPerson', true)
      }
  }, [props.person, setValue])

  const onFormSubmit = async (data: IPhoneCodeCheckDetails) => {
      
     if(props.person && props.person.ssn){
        
      if(data.ssn4 !== props.person.ssn.substring(props.person.ssn.length - 4)){
        setErrorMessage("SSN doesn't match user info")
      }else{
        props.onCompleted(props.person.ssn)
      }
     }else{
      props.onCompleted(data.ssn)      
     }
      
  }

  return (
    <div data-testid="SsnForm" className={'ni-signers-form ' + props.className} style={props.style}>
      {errorMessage && (
        <div role="alert" className="alert toast is-error">
          <AlertCircle /> {errorMessage}
        </div>
      )}
      <form className="ssn-form-look" onSubmit={handleSubmit(onFormSubmit)}>

        {props.person && props.person.ssn ? <div><Controller control={control} name="ssn4" as={<CodeInput fields={4} />} />
        <ErrorMessage errors={errors.ssn4 as any} /></div>: <div><Controller control={control} name="ssn" as={<CodeInput fields={9} />} />
        <ErrorMessage errors={errors.ssn as any} /></div>}

        <div className="controls">
          <Button
            type="submit"
            disabled={Object.keys(errors).length > 0}
            className="is-pill is-green has-icon-after"
          >
            Proceed
          </Button>
        </div>
      </form>
    </div>
  )
}
