import React, { useRef, useCallback } from 'react'
import Webcam from 'react-webcam'
import { log } from '../../../services'
import { CaptureButton } from './CaptureButton'
import { NoCameraMessage } from '../NoCameraMessage/NoCameraMessage'
import camFlipIcon from './camFlipIcon.svg'
import './photo-capture.sass'
import useWindowDimensions from '../../windowDimensions'
interface IPhotoCapture {
  onTakePhoto: (photoDataUri: string) => void
  className?: string
  style?: React.CSSProperties
  title?: string
  width?: number
  height?: number
  facingMode?: string
  onError?: (error: string) => void
  onFlipCamera?: () => void
  allowFlip?: boolean
}

const defaultProps = {
  width: 1200,
  height: 740,
  facingMode: 'user',
}

/**
 * PhotoCapture
 *
 * @param props IPhotoCapture
 */
export const PhotoCapture: React.FC<IPhotoCapture> = (props: IPhotoCapture) => {
  const [message, setMessage] = React.useState('')
  const { height, width } = useWindowDimensions();

  // need to destructure this prop
  const { onTakePhoto, onFlipCamera, allowFlip } = props
  const webcamRef = useRef<Webcam>(null)

  const capture = useCallback(() => {
    const current = webcamRef.current
    if (current) {
      const imageSrc = current?.getScreenshot()
      log.info('took picture', 'photoCapture')
      onTakePhoto(imageSrc || '')
    }
  }, [webcamRef, onTakePhoto])

  const handleNoCameraError = (error: string) => {
    const msg = `We didn't find a camera on this device`
    log.warn('camera not working', 'cameraPhoto')
    document.body.style.overflow = 'auto'
    setMessage(msg)
  }

  // if there is an error, show no camera message
  if (message) {
    return (
      <NoCameraMessage title={message}>
        <p>
          Make sure you clicked <b>Allow</b> on the tooltip prompted by your browser.
        </p>
        <p>
          If you don't have a camera on this device scan this QR code with your smartphone or tablet to complete the
          process.
        </p>
      </NoCameraMessage>
    )
  }

  return (
    <div
      data-testid="photoCapture"
      className={'ni-photo-capture facing-' + props.facingMode + ' ' + props.className}
      style={props.style}
    >
    {props.facingMode === 'environment'
    ?
    <div id='overlay' style={{width:'100%', height:'100%', zIndex:10, borderStyle:'solid',borderWidth: '150px 300px', borderColor:'rgba(0, 0, 0, 0.31)', position:'absolute'}}></div>
    :
    (width >1448 && height >740)?<div style={{marginLeft:'38%',marginTop:'5%',width:'25%',minWidth:'350px', height:'75%', zIndex:100,borderStyle:'dotted',borderWidth: '5px', borderColor:'red', borderRadius:'380px', position:'absolute'}}></div>:null}
      <div className="ni-photo-capture-content">
        {props.title && <small className="caption">{props.title}</small>}
        <div className="video-container">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            imageSmoothing={false}
            videoConstraints={{ width: props.width, height: props.height, facingMode: props.facingMode }}
            onUserMediaError={props.onError || handleNoCameraError}
          />
        </div>
        <CaptureButton onClick={capture} />
        {allowFlip && (
          <button className={'flip-button button is-icon is-pill'} onClick={onFlipCamera} data-testid="flipButton">
            <img src={camFlipIcon} alt="flip camera icon" />
          </button>
        )}
      </div>
    </div>
  )
}

PhotoCapture.defaultProps = defaultProps
