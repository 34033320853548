import { IFormRepeatableSigners } from '../../components/Forms/InvitesForm/RepeatableSigner/RepeatableSigner'
import { FormatHelper } from '../FormatHelper'

/**
 * Validate if we have at least one signer
 * @param data - IFormRepeatableSigners
 */
export const validateNumberSigners = (data: IFormRepeatableSigners): boolean | string => {
  const { signers } = data
  return !FormatHelper.isEmpty(signers)
}
