import React from 'react'

interface ILogo {
  url: string
  alt: string
  width?: string
  onClick?: () => void
  className?: string
  style?: React.CSSProperties
}

const defaultProps = {
  url: '/mocks/company.png',
  height: '100px',
  alt: 'Company logo',
}

/**
 * Logo
 *
 * @param props ILogo
 */
export const Logo: React.FC<ILogo> = (props: ILogo) => {
  return (
    <div data-testid="logo" className={`${props.className}`} style={props.style}>
      <img
        className="ni-logo-img"
        alt={props.alt}
        src={props.url}
        style={{ maxWidth: props.width }}
        onClick={props.onClick}
      />
    </div>
  )
}

Logo.defaultProps = defaultProps
