import React from 'react'
import { useParams } from 'react-router-dom'
import { useEmailVerificationStatus } from '../../hooks/UseEmailVerification'
import { useCreateEmailVerification } from '../../hooks/useCreateEmailVerification'
import { AlertCircle } from 'react-feather'
import iconEmailVerified from './pc_checkmark_ok.svg'
import IconLinkExpired from './pc_checkmark_warning.svg'
import { Loading } from '../../components/Common/Loading/Loading'
import { log } from '../../services'

import './ConfirmEmail.sass'

interface IConfirmEmail {
  className?: string
  style?: React.CSSProperties
  title?: string
}
interface IParamsTypes {
  signerId: string
  verificationId: string
}
/**
 * Email Verification Status
 *
 * @param props ISConfirmEmail
 */
export const ConfirmEmail: React.FC<IConfirmEmail> = (props: IConfirmEmail) => {
  const { signerId, verificationId } = useParams<IParamsTypes>()
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token') || ''
  const { status, mutate, error } = useCreateEmailVerification()
  const { status: statusPut, error: errorPut } = useEmailVerificationStatus(signerId, verificationId, token)

  const handleResend = () => {
    log.info({ signerId, token }, 'onResendClick')
    mutate({ signerId, token })
  }
  return (
    <div className={'mainContainer_wrapper'} style={props.style} data-testid="confirm-email">
      {statusPut === 'loading' ? (
        <Loading fullPage={false} active={true} />
      ) : (
        <div className="mainContainer">
          {statusPut === 'success' ? (
            <div className="ni-step-confirm-email">
              <img src={iconEmailVerified} alt="email verified" />
              <h3>Thank you!</h3>
              <p>Your email account was successfully verified.</p>
            </div>
          ) : (
            <div className="ni-step-confirm-email">
              <img src={IconLinkExpired} alt="email confirmation link expired" />
              {errorPut === 409 ? (
                <h3>Your email confirmation link expired!</h3>
              ) : (
                <h3>Your email confirmation link didn't work!</h3>
              )}
              <p>Please click the resend button to receive a new verification email</p>
              {error && (
                <div role="alert" className="alert toast is-error" data-testid="error">
                  <AlertCircle /> Sorry, we couldn't send the email.
                </div>
              )}
              <button className="button is-pill is-green" onClick={handleResend} data-testid="resendButton">
                {status === 'success' ? 'Sent!' : 'Resend Verification Email'}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
