import React from 'react'
import './Footer.sass'

import { Link } from 'react-router-dom'

export const Footer: React.FC = () => {
  return (
    <div className="ni-footer">
      <p>©2021 NimbleFi, All rights reserved.</p>
      <Link to="/onboarding/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </Link>
    </div>
  )
}
