import CoreAPI from './CoreAPI'

export interface IVerificationCreationObject {
  phoneNumber: string
}
export interface IVerificationCheckObject {
  phoneNumber: string,
  code: string
}
export interface ICustomerDetails {
  name: string
  email: string
}

/* Logic for creating and retrieving CustomerLookup information from core api */
class CustomerLookup extends CoreAPI {
 
  public async findCustomerByPhoneNumber(phoneNumber: string) {
    // return await this.getRequest(`/customer-lookup/${phoneNumber}`, )

    return [
        {
            name: 'John Mark',
            email: 'johnmark@gmail.com'
        },
        {
            name: 'John Paul',
            email: 'johnpaul@gmail.com'
        }
    ]
  }
}

export default new CustomerLookup()
