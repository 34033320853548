import React from 'react'

interface IVideoOverlay {
  state: string
}
export const VideoOverlay = (props: IVideoOverlay) => {
  return (
    <div
      className={`video-overlay ${
        props.state === '' || props.state === 'flip' || props.state === 'done' ? 'active' : ''
      }`}
    />
  )
}
