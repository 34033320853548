import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { log } from '../../../services'
import { ProspectForm } from '../..'
import { ProspectContext, useDocumentState } from '../../../store'
import { ISignerDetails } from '../../../store/reducers/ProspectReducer'
import './StepPersonalInfo.sass'
import { LoadingContext } from '../../../store/LoadingContext'
import { AlertCircle } from 'react-feather'

interface IStepPersonalInfo {
  className?: string
  style?: React.CSSProperties
}

/**
 * Step Prospect Personal Info
 *
 * @param props IStepPersonalInfo
 */
export const StepPersonalInfo: React.FC<IStepPersonalInfo> = (props: IStepPersonalInfo) => {
  const history = useHistory()
  const { setLoading } = useContext(LoadingContext)

  const { prospect, validateSigner, updateSigner } = useContext(ProspectContext)
  // documents
  const { documents } = useDocumentState()
  const { front, back } = documents

  React.useEffect(() => {
    const checkIfDocumentsPresent = () => {
      if (front) {
        log.info('We have photos', 'StepPersonalInfo')
        return true
      }
      return false
    }

    if (!checkIfDocumentsPresent()) {
      log.error('We are missing documents in state', 'StepPersonalInfo')
      history.push('/onboarding')
    }
  }, [front, back, history])

  // Validation to check for duplications between multiple signers on an application
  const handleValidation = async (data: ISignerDetails) => {
    log.info(JSON.stringify(data), 'StepPersonalInfo')

    const results = await validateSigner({ ...data })
    return results
  }

  /** after submit, post to server and get response */
  const handleSubmit = async (data: ISignerDetails) => {
    log.info(JSON.stringify(data), 'StepPersonalInfo')

    // We only allow validated prospects to progress
    if (prospect.signer.validated) {
      setLoading(true)
      // update state
      await updateSigner({ ...prospect.signer, consent: true, ...data })
      setLoading(false)
      history.push('/onboarding/choose-products')
    }
  }

  return (
    <div data-testid="StepPersonalInfo" className={'ni-step-personal-info ' + props.className} style={props.style}>
      {prospect.error && (
        <div role="alert" className="alert toast is-error">
          <AlertCircle /> {prospect.error}
        </div>
      )}
      <h1 className="text-center">
        <small>Please Review your</small>
        Personal Information
      </h1>
      <ProspectForm
        defaultValues={prospect.signer}
        signerId={prospect.signerId}
        onValidate={handleValidation}
        onSubmit={handleSubmit}
        invitee={prospect.inviteeToken ? false : true}
      />
    </div>
  )
}
