import React from 'react'
import './ErrorMessage.sass'
import { FieldError } from 'react-hook-form'

interface IErrorMessage {
  className?: string
  style?: React.CSSProperties
  errors?:
    | {
        message?: string
      }
    | FieldError
}

/**
 * ErrorMessage
 *
 * @param props IErrorMessage
 */
export const ErrorMessage: React.FC<IErrorMessage> = (props: IErrorMessage) => {
  if (props.errors) {
    return (
      <span
        role="alert"
        className={props.className ? `${props.className} field-error` : 'field-error'}
        data-testid="ErrorMessage"
      >
        {props.errors.message}
      </span>
    )
  }
  return null
}
