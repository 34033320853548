import React from 'react'
import { IProduct } from '../../../store/reducers/ProspectReducer'

interface IProductComponent {
  product: IProduct
  submitProduct: (productId: string, productName: string) => void
  showProduct: (productId: string) => void
}

export const ProductSummary: React.FC<IProductComponent> = ({ product, submitProduct, showProduct }) => {
  const chooseProduct = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    submitProduct(product.id, product.name)
  }
  const showDetails = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    showProduct(product.id)
  }

  return (
    <div className="product" onClick={showDetails} data-testid="product">
      <div className="description">
        <h3>{product.name}</h3>
        <p>{product.summary}</p>
        <button>Find out more</button>
      </div>
      <div className="product-side">
        {/* TODO uncomment and do proper verifications*/}
        <div className="enroll">
          <button className="button is-pill is-green" data-testid="submitProduct" onClick={chooseProduct}>
            Enroll
          </button>
        </div>
      </div>
    </div>
  )
}
