import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button, ErrorMessage } from '../../index'
import PhoneVerificationService from '../../../services/PhoneVerification'
import { Controller } from 'react-hook-form'
import './PhoneNumberCodeCheckForm.sass'
import { AlertCircle } from 'react-feather'
import CodeInput from '../../Common/CodeInput/CodeInput'

const schema = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .min(5, 'Code cannot have less than 5 characters')
    .max(5, 'Code cannot have more than 5 characters'),
})

export interface IPhoneCodeCheckDetails {
  code: string
}

interface IPhoneNumberCheckForm {
  style?: React.CSSProperties
  className?: string
  phoneNumber: string
  onCompleted: () => void
}

/**
 * Verification Form
 *
 * @param props IPhoneNumberCheckForm
 */
export const PhoneNumberCodeCheckForm: React.FC<IPhoneNumberCheckForm> = (props: IPhoneNumberCheckForm) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { control, handleSubmit, errors } = useForm<IPhoneCodeCheckDetails>({
    resolver: yupResolver(schema),
  })

  const onFormSubmit = async (data: IPhoneCodeCheckDetails) => {
    setLoading(true)
    try {
      const response = await PhoneVerificationService.checkPhoneVerification({
        phoneNumber: props.phoneNumber,
        code: data.code.replace(/-/g, ''),
      })
      if (response.sid) {
        return props.onCompleted()
      }
      throw new Error('Failed to send message')
    } catch (error) {
      setErrorMessage((error as any).message || 'Failed to send message')
    }
    setLoading(false)
  }

  return (
    <div data-testid="PhoneNumberVerificationForm" className={'ni-signers-form ' + props.className} style={props.style}>
      {errorMessage && (
        <div role="alert" className="alert toast is-error">
          <AlertCircle /> {errorMessage}
        </div>
      )}
      <form className="phone-form-look" onSubmit={handleSubmit(onFormSubmit)}>
        <Controller control={control} name="code" as={<CodeInput fields={5} />} />
        <ErrorMessage errors={errors.code as any} />

        <div className="controls">
          <Button
            type="submit"
            loading={loading}
            disabled={Object.keys(errors).length > 0 || loading}
            className="is-pill is-green has-icon-after"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
