import React, { ReactChild } from 'react'

interface IProvider {
  children: ReactChild
}

interface ILoadingContext {
  loading: boolean
  setLoading: (loading: boolean) => void
}
export const LoadingContext = React.createContext({} as ILoadingContext)

export const useLoading = (initialState?: boolean | false) => {
  const context = React.useContext(LoadingContext)
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  return context
}

export const LoadingProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}
