import React from 'react'
import { personInfo as personInfoService, log } from '../services'
import { IPersonInfoRequest, IPersonInfo } from '../services/PersonInfo'
import { /*setPersistState,*/ getPersistState } from '../utils/PersistState'
import * as Sentry from '@sentry/react'


interface IState {
  status: string
  error?: string
  person: IPersonInfo | null
}

const initialState = {
  status: 'loading',
  error: undefined,
  person: {
    ssn: null,
  },
}

/* Types for the reducer */
interface IAction {
  type: 'get' | 'resolve' | 'remove' | 'reject' | 'cancel' | 'reset'
}
interface IRejectAction extends IAction {
  payload: string
}
interface IResolveAction extends IAction {
  person: IPersonInfo | null
}
const reducer = (prevState: IState, action: IAction) => {
  switch (action.type) {
    case 'get':
      return {
        ...prevState,
        status: 'loading',
      }
    case 'resolve':
      return {
        ...prevState,
        status: 'success',
        person: (action as IResolveAction).person,
        error: undefined,
      }
    case 'reject':
      return {
        ...prevState,
        status: 'failure',
        error: (action as IRejectAction).payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

/* Types for the Context and Provider */
type IReducer = (prevState: IState, action: IAction | IRejectAction | IResolveAction) => IState
type IDispatch = (action: IAction | IRejectAction | IResolveAction) => void
interface IProviderProps {
  children: React.ReactNode
  initialProviderState?: IState
}

/* Note: exporting this because it's useful in tests */
export const SsnStateContext = React.createContext<IState | undefined>(undefined)
const SsnDispatchContext = React.createContext<IDispatch | undefined>(undefined)

const SsnProvider = (props: IProviderProps) => {
  const [state, dispatch] = React.useReducer<IReducer>(
    reducer,
    props.initialProviderState || getPersistState('SSN') || initialState
  )

  //prevent caching the value for now
  // React.useEffect(() => {
  //   setPersistState('SSN', state)
  // }, [state])

  return (
    <SsnStateContext.Provider value={state}>
      <SsnDispatchContext.Provider value={dispatch}>{props.children}</SsnDispatchContext.Provider>
    </SsnStateContext.Provider>
  )
}

/* Declare our Hooks */
const useSsnState = () => {
  const context = React.useContext(SsnStateContext)
  if (context === undefined) {
    throw new Error('useSsnState must be used within a SsnProvider')
  }
  return context
}

const useSsnDispatch = () => {
  const context = React.useContext(SsnDispatchContext)
  if (context === undefined) {
    throw new Error('useSsnDispatch must be used within a SsnProvider')
  }
  return context
}

const getPersonInfo = async (
  dispatch: IDispatch,
  personRequest: IPersonInfoRequest
) => {
  dispatch({ type: 'get' })
  try {
    log.info(`getting person's information with SSN`, 'getPersonInfo')
     
    const getPersonInfo = await personInfoService.getPersonInfo(personRequest)
    dispatch({ type: 'resolve', person: getPersonInfo })
  } catch (error) {
    Sentry.captureException(error)
    log.error(error, 'getPersonInfo')
    dispatch({ type: 'reject', payload: 'Failed to get person info' })
  }
}

// const updatePersonSsn = (dispatch: IDispatch, ssn: string) => {
//       dispatch({ type: 'resolve', person: {ssn}})
// }

export { SsnProvider, useSsnState, useSsnDispatch, getPersonInfo }
