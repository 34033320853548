interface ILoanConfig {
  enabled: boolean
  products: string[]
}

interface ICardConfig {
  enabled: boolean
  products: string[]
}

interface IFeatureConfig {
  loans: ILoanConfig
  cards: ICardConfig
}

const defaultLoanConfig = {
  enabled: false,
  products: [],
}

const defaultCardConfig = {
  enabled: false,
  products: [],
}

const defaultFeatureConfig: IFeatureConfig = {
  loans: defaultLoanConfig,
  cards: defaultCardConfig,
}

class Config {
  public readonly coreAPI: string
  public readonly env: string
  public readonly domain: string
  public readonly mockAPI: boolean
  public readonly mockPhotos: boolean
  public readonly skipRoutes: boolean
  public readonly gtmID: string | undefined
  public readonly gtmAuth: string | undefined
  public readonly gtmPreview: string | undefined
  public readonly sentryDSN: string | undefined
  public readonly releaseVersion: string | undefined
  public readonly releaseVersionNumber: string | undefined
  public readonly context: string
  public readonly microblinkAuth: string
  public readonly microblinkEngineLocation: string
  public readonly featureConfig: IFeatureConfig = defaultFeatureConfig

  constructor() {
    const env =
      ((window as { [key: string]: any })._env_ as {
        [key: string]: any
      }) || process.env // fallback to process.env (in case we are running inside jest test-runner)

    this.coreAPI = env.REACT_APP_API_URL
    this.domain = env.REACT_APP_DOMAIN
    this.env = env.NODE_ENV
    // mock response from api calls
    this.mockAPI = !!(env.REACT_APP_MOCK_API_CALLS === 'true')
    // send photos to api which will always succeed
    this.mockPhotos = !!(env.REACT_APP_MOCK_PHOTOS === 'true')

    this.skipRoutes = !!(env.REACT_APP_SKIP_ROUTES === 'true')

    // google tag manager var's
    this.gtmID = env.REACT_APP_GTM_ID
    this.gtmAuth = env.REACT_APP_GTM_AUTH
    this.gtmPreview = env.REACT_APP_GTM_PREVIEW

    // microblink
    this.microblinkAuth = env.REACT_APP_MICROBLINK_AUTH
    this.microblinkEngineLocation = 'https://unpkg.com/@microblink/blinkid-in-browser-sdk@5.9.1/resources/'

    this.sentryDSN = env.REACT_APP_SENTRY_DSN || undefined
    this.releaseVersionNumber = env.REACT_APP_VERSION
    this.releaseVersion = this.getRelease()
    this.context = this.getContext()

    /*************************/
    /* Feature Configuration */
    /*************************/

    // Loans
    const enabledLoans = env.FEATURE_LOANS_PRODUCTS_ENABLED?.split(',') || []
    this.featureConfig.loans.products = enabledLoans.map((name: string) => name.trim())
    this.featureConfig.loans.enabled = enabledLoans.length >= 1

    // Cards
    const enabledCards = env.FEATURE_CARDS_PRODUCTS_ENABLED?.split(',') || []
    this.featureConfig.cards.products = enabledCards.map((name: string) => name.trim())
    this.featureConfig.cards.enabled = enabledCards.length >= 1
  }

  public getRelease() {
    return `NimbleOnboarding@${this.releaseVersionNumber}`
  }

  public getContext() {
    const subdomain = this.coreAPI.split('.')[1] ? this.coreAPI.split('.')[1] : false

    switch (subdomain) {
      case 'dev':
        return 'dev'
      case 'app':
        return 'production'
      case 'demo':
        return 'demo'
      case 'qa':
        return 'qa'
      default:
        return 'localhost'
    }
  }
}

export default new Config()
