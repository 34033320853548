import React from 'react'
import { ArrowLeft, Check } from 'react-feather'
import dompurify from 'dompurify'
import { IProduct } from '../../../store/reducers/ProspectReducer'

interface IProductDetail {
  product: IProduct
  toggleProductDetailView: (isProductDetailVisible: boolean) => void
  submitProduct: (productId: string, productName: string) => void
}
export const ProductDetail: React.FC<IProductDetail> = ({ product, toggleProductDetailView, submitProduct }) => {
  const chooseProduct = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    submitProduct(product.id, product.name)
  }

  const backToProductList = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleProductDetailView(false)
  }

  const sanitizer = dompurify.sanitize

  return (
    <div className="product-detail">
      <div className="product-detail-nav">
        <button className="back" onClick={backToProductList}>
          <ArrowLeft color="#ABAFB3" />
          Back
        </button>
        <button className="button is-pill is-green" data-testid="enroll-nav" onClick={chooseProduct}>
          <Check />
          Enroll
        </button>
      </div>
      <div className="product-detail-content">
        <h1>{product.name}</h1>
        <div
          className="content"
          data-testid="content"
          dangerouslySetInnerHTML={{ __html: sanitizer(product.content) }}
        />
      </div>
      <button className="button is-pill is-green enroll-bottom" data-testid="enroll-bottom" onClick={chooseProduct}>
        <Check />
        Enroll
      </button>
    </div>
  )
}
