import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { PhoneVerificationContext, ProspectContext } from '../../../store'

import './StepEmailEntry.sass'
import { EmailEntryForm } from '../../Forms/EmailEntryForm/EmailEntryForm'

interface IStepEmailEntry {
  className?: string
  style?: React.CSSProperties
}

export const StepEmailEntry: React.FC<IStepEmailEntry> = (props: IStepEmailEntry) => {
  const history = useHistory()
  const { phoneVerification, updateEnteredEmail } = useContext(PhoneVerificationContext)
  const { prospect, updateSigner } = useContext(ProspectContext)

  const handleOnCompleted = async (email: string) => {
    updateSigner({ ...prospect.signer, email })
    updateEnteredEmail(email)
    history.push('/onboarding/capture-documents')
  }

  return (
    <div data-testid="step-phone-verification" className={`text-center`}>
      <div className="max-wrapper">
        <h3>Email Entry</h3>
        <h2>We will need you to provide a verifiable email address</h2>
        <EmailEntryForm customerDetails={phoneVerification.customerDetails || []} onCompleted={handleOnCompleted} />
      </div>
    </div>
  )
}
