import React, { useState } from 'react'
import ReactSwitch from 'react-switch'
import { Link, useHistory } from 'react-router-dom'
import { useConsents } from '../../../store'
import './StepDisclosure.sass'

interface IStepDisclosure {
  className?: string
  style?: React.CSSProperties
}

export const StepDisclosure: React.FC<IStepDisclosure> = (props: IStepDisclosure) => {
  const history = useHistory()
  const { updateConsent } = useConsents()
  const [checkedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedOthers, setCheckedOthers] = useState(false)

  const onButtonClick = () => {
    updateConsent('terms', true)
    history.push('/onboarding/phone-verification')
  }

  return (
    <div data-testid="step-disclosure" className={`text-center`}>
      <div className="intro-text">
        <h3>Disclosures</h3>
        <h2>By Continuing, you agree with the following disclosures</h2>
        <div className="disclosure-holder">
          <div className="disclosure">
            <div className="switcher">
              <ReactSwitch
                onChange={setCheckedPrivacyPolicy}
                checked={checkedPrivacyPolicy}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                handleDiameter={16}
              />{' '}
              <Link to="/onboarding/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
            </div>

            <div className="switcher">
              <ReactSwitch
                onChange={setCheckedTerms}
                checked={checkedTerms}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                handleDiameter={16}
              />{' '}
              <Link to="/onboarding/terms-and-conditions" target="_blank">
                Terms And Conditions
              </Link>
            </div>
            <div className="switcher">
              <ReactSwitch
                onChange={setCheckedOthers}
                checked={checkedOthers}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                handleDiameter={16}
              />{' '}
              <Link to="/onboarding/terms-and-conditions" target="_blank">
                Others
              </Link>
            </div>
          </div>
        </div>
        <button
          onClick={onButtonClick}
          className="button is-pill is-green"
          disabled={!checkedPrivacyPolicy || !checkedTerms || !checkedOthers}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
