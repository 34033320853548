import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { log } from '../../../services'
import { PhotoCapture, PhotoPreviewWithControls } from '../..'
import { Lottie } from '@alfonmga/react-lottie-light-ts'
import animationData from '../../../animations/camera.json'
import { defaultOptions } from '../../../animations/options'
import { uploadDocument, useDocumentState, useDocumentDispatch } from '../../../store/DocumentsContext'
import { AlertCircle } from 'react-feather'
import { LoadingContext } from '../../../store/LoadingContext'
import { InstitutionContext } from '../../../store/InstitutionContext'

/**
 * Step Front Document
 *
 * @param props IStepPersonalInfo
 */
interface IStepFrontDocument {
  className?: string
  style?: React.CSSProperties
}
export const StepFrontDocument: React.FC<IStepFrontDocument> = (props: IStepFrontDocument) => {
  const history = useHistory()
  const institution = useContext(InstitutionContext)
  const { status, error } = useDocumentState()
  const dispatch = useDocumentDispatch()
  const paramsArray = history.location.search.split('&')
  const [photo, setPhoto] = useState('')
  const [cameraState, setCameraState] = useState(photo ? 'camera-active' : 'closed')
  const { setLoading } = useContext(LoadingContext)

  const [photoCaptureMsg, setphotoCaptureMsg] = useState(
    'Make sure that the front of your document is visible and readable.'
  )

  const animationOptions = {
    ...defaultOptions,
    animationData,
  }

  React.useEffect(() => {
    if (paramsArray[0] === '?camera-active') {
      log.info('Using fallback', 'open camera')
      setphotoCaptureMsg(
        `
        We couldn't automatically scan your ID.
        Please present the front of your document again and click the button below when ready.
        `
      )
      setCameraState('camera-active')
    }

    const goToNextStep = () => {
      log.info('continue to next step', 'cameraPhoto')
      dispatch({ type: 'cancel' })
      if (paramsArray[1] === 'PASSPORT') {
        history.push('/onboarding/selfie')
      } else {
        history.push('/onboarding/back')
      }
      return
    }
    if (status === 'success') {
      goToNextStep()
    }
    if (status === 'failure') {
      setCameraState('closed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const handleTakePhoto = (photoDataUri: string) => {
    log.info('front done', 'cameraPhoto')
    setPhoto(photoDataUri)
    setCameraState('preview-active')
  }

  const handleContinue = async () => {
    // post to server and save state
    setLoading(true)
    await uploadDocument(dispatch, photo, 'front', institution!.id)
    setLoading(false)
  }

  const handleRestartPhoto = () => {
    log.info('remove front', 'cameraPhoto')
    setPhoto('')
    setCameraState('camera-active')
  }

  return (
    <div data-testid="step-front" className={`step-front camera-step text-center ${cameraState}`}>
      <PhotoCapture facingMode={'environment'} title={photoCaptureMsg} onTakePhoto={handleTakePhoto} />

      <div className="intro-text">
        <h3>We need to verify your identity</h3>
        <h2>
          Let’s start by scaning the <b>Front</b> of your Document
        </h2>
        <div className="camera-animation">
          <Lottie config={animationOptions} />
        </div>
        <button onClick={handleRestartPhoto} className="button is-pill is-green">
          Open Camera
        </button>
      </div>

      <PhotoPreviewWithControls
        alt={'Front Preview'}
        imageData={photo}
        onRepeat={handleRestartPhoto}
        onContinue={handleContinue}
        loading={status === 'create'}
      />

      {error && (
        <div role="alert" className="alert toast is-error u-margin-top-xl">
          <AlertCircle /> {error}
        </div>
      )}
    </div>
  )
}
