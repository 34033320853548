import React from 'react'
import './Document.sass'

interface ILegalDocument {
  children: React.ReactNode
}

export const Document: React.FC<ILegalDocument> = ({ children }) => {
  return <div className="doc_wrapper">{children}</div>
}
