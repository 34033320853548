import React from 'react'

interface IPreview {
  children?: React.ReactNode
  onClick(event: React.MouseEvent<HTMLButtonElement>): void
}

/* Capture Button for camera */
export const CaptureButton = (props: IPreview) => {
  return (
    <button className="capture-button button is-icon is-pill" onClick={props.onClick} data-testid="CaptureButton">
      {props.children}
    </button>
  )
}
