import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { log } from '../../../services'
import { LoadingContext } from '../../../store/LoadingContext'
import { ProspectContext } from '../../../store'
import './StepChooseProductOptions.sass'
import { ProductOptionsForm } from '../../Forms/ProductOptionsForm/ProductOptionsForm'
import { IProductOption } from '../../../store/reducers/ProspectReducer'

interface IChooseProductOptions {
  className?: string
  style?: React.CSSProperties
}
export const StepChooseProductOptions: React.FC<IChooseProductOptions> = (props: IChooseProductOptions) => {
  const history = useHistory()
  const { setLoading } = useContext(LoadingContext)
  const { prospect, updateProductOptions } = useContext(ProspectContext)

  const backToProducts = () => {
    history.goBack()
  }

  // ISSUE-33 Begin
  const { updateInitialDeposit } = useContext(ProspectContext)
  const chooseAmountFlow = async () => {
    await updateInitialDeposit(0)

    if (prospect.inviteeToken === undefined || prospect.inviteeToken === null) {
      history.push('/onboarding/invite-signers')
    } else {
      history.push('/onboarding/success')
    }
  }
  // ISSUE-33 End

  const handleSubmit = async (data: IProductOption[]) => {
    setLoading(true)
    log.info(JSON.stringify(data), 'StepChooseProductOptions')
    updateProductOptions(data)
    setLoading(false)
    if (prospect.selectedProduct !== undefined) {
      if (prospect.selectedProduct.category === 'CARD') {
        history.push('/onboarding/invite-signers')
      } else if (prospect.selectedProduct.category === 'LOAN') {
        history.push('/onboarding/invite-signers')
      } else {
        chooseAmountFlow()
      }
    }
  }
  return (
    <div data-testid="StepChooseProductOptions" className={'ni-step-signers ni-test product-options'}>
      <h3 data-testid="SelectedProduct"> Additional Features</h3>
      <h1>Make the most of your new account</h1>
      <p className={'subtitle'}>
        Select which additional product do you wish to add to your new {prospect.selectedProductName} account
      </p>
      {prospect.selectedProduct && (
        <ProductOptionsForm
          onSubmit={handleSubmit}
          options={prospect.selectedProduct.options.filter((option) => option.category === 'product_feature')}
          onBack={backToProducts}
        />
      )}
    </div>
  )
}
