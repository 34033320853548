import ReactCodeInput from 'react-verification-code-input'
import React from 'react'
import './CodeInput.sass'

interface ICodeInput {
  className?: string
  fields: number
  onChange?: (val: string) => void
  values?: string[]
  type?: 'number' | 'text' | undefined
}

const CodeInput: React.FC<ICodeInput> = (props: ICodeInput) => {
  return (
    <ReactCodeInput
      fields={props.fields}
      autoFocus
      className="code-input"
      values={props.values}
      onChange={props.onChange}
      type={props.type || 'number'}
    />
  )
}

export default CodeInput
