import React from 'react'
import { ICustomerDetails } from '../services/CustomerLookup'
import { setPersistState, getPersistState } from '../utils/PersistState'

export interface IPhoneVerification {
  phoneNumber?: string
  customerDetails?: ICustomerDetails[]
  selectedCustomer?: ICustomerDetails
  enteredEmail?: string
}

interface IPhoneVerificationState extends IPhoneVerification {
  error?: string
}
const initialState = {
  phoneNumber: null,
  customerDetails: [],
  selectedCustomer: null,
  enteredEmail: null,
}

/* Types for the reducer */
interface IAction {
  type: 'update'
  payload: any
  key: string
}

interface IPhoneVerificationContext {
  phoneVerification: IPhoneVerificationState
  updatePhoneNumber: (phoneNumber?: string) => void
  updateCustomerDetails: (customerDetails?: ICustomerDetails[]) => void
  updateSelectedCustomer: (customer?: ICustomerDetails) => void
  updateEnteredEmail: (email?: string) => void
}

const reducer = (prevState: IPhoneVerificationState, action: IAction) => {
  switch (action.type) {
    case 'update':
      return {
        ...prevState,
        [action.key]: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

/* Types for the Context and Provider */
type IReducer = (prevState: IPhoneVerificationState, action: IAction) => IPhoneVerificationState

const PhoneVerificationContext = React.createContext<IPhoneVerificationContext>({} as IPhoneVerificationContext)

interface IProviderProps {
  children: React.ReactNode
}

const PhoneVerificationProvider = ({ children }: IProviderProps) => {
  const [state, dispatch] = React.useReducer<IReducer>(reducer, getPersistState('PHONE_VERIFICATION') || initialState)

  React.useEffect(() => {
    setPersistState('PHONE_VERIFICATION', state)
  }, [state])

  const updatePhoneNumber = (phoneNumber?: string) => {
    dispatch({ type: 'update', key: 'phoneNumber', payload: phoneNumber })
  }
  const updateCustomerDetails = (customerDetails?: ICustomerDetails[]) => {
    dispatch({ type: 'update', key: 'customerDetails', payload: customerDetails })
  }
  const updateSelectedCustomer = (customerDetails?: ICustomerDetails) => {
    dispatch({ type: 'update', key: 'selectedCustomer', payload: customerDetails })
  }
  const updateEnteredEmail = (email?: string) => {
    dispatch({ type: 'update', key: 'enteredEmail', payload: email })
  }

  return (
    <PhoneVerificationContext.Provider
      value={{
        phoneVerification: state,
        updatePhoneNumber,
        updateCustomerDetails,
        updateSelectedCustomer,
        updateEnteredEmail,
      }}
    >
      {children}
    </PhoneVerificationContext.Provider>
  )
}

export { PhoneVerificationProvider, PhoneVerificationContext }
