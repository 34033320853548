import React from 'react'
import './AlertMessage.sass'

interface IAlertMessage {
  className?: string
  style?: React.CSSProperties
  title?: string
  children?: React.ReactNode
}

const defaultProps = {
  title: 'Example',
}

/**
 * AlertMessage
 *
 * @param props IAlertMessage
 */
export const AlertMessage: React.FC<IAlertMessage> = (props: IAlertMessage) => {
  return (
    <div data-testid="AlertMessage" className={`ni-alert-message ${props.className}`} style={props.style}>
      {props.title && <h4 className="title">{props.title}</h4>}
      {props.children}
    </div>
  )
}

AlertMessage.defaultProps = defaultProps
