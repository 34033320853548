import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { log } from '../../../services'
import { PhotoCapture, PhotoPreviewWithControls } from '../..'
import { uploadDocument, useDocumentState, useDocumentDispatch } from '../../../store/DocumentsContext'
import { AlertCircle } from 'react-feather'
import { LoadingContext } from '../../../store/LoadingContext'
import { InstitutionContext } from '../../../store/InstitutionContext'
import { Lottie } from '@alfonmga/react-lottie-light-ts'
import animationData from '../../../animations/camera.json'
import { defaultOptions } from '../../../animations/options'

interface IStepBackDocument {
  className?: string
  style?: React.CSSProperties
}
export const StepBackDocument: React.FC<IStepBackDocument> = (props: IStepBackDocument) => {
  const history = useHistory()

  const institution = useContext(InstitutionContext)
  const { setLoading } = useContext(LoadingContext)
  const { status, error } = useDocumentState()
  const dispatch = useDocumentDispatch()

  const [photo, setPhoto] = useState('')
  const [cameraState, setCameraState] = useState(photo ? 'camera-active' : 'closed')

  const animationOptions = {
    ...defaultOptions,
    animationData,
  }

  React.useEffect(() => {
    const goToNextStep = () => {
      log.info('continue to next step', 'cameraPhoto')
      dispatch({ type: 'cancel' })
      history.push('/onboarding/confirm-id')
      return
    }
    if (status === 'success') {
      goToNextStep()
    }
    if (status === 'failure') {
      setCameraState('closed')
    }
  }, [status, history, dispatch])

  const handleTakePhoto = (photoDataUri: string) => {
    log.info('back done', 'cameraPhoto')
    setPhoto(photoDataUri)
    setCameraState('preview-active')
  }

  const handleContinue = async () => {
    // post to server and save state
    setLoading(true)
    await uploadDocument(dispatch, photo, 'back', institution!.id)
    setLoading(false)
  }

  const handleRestartPhoto = () => {
    log.info('remove back and open cam', 'cameraPhoto')
    setPhoto('')
    setCameraState('camera-active')
  }

  return (
    <div data-testid="step-back" className={`step-back camera-step text-center ${cameraState}`}>
      <PhotoCapture
        facingMode={'environment'}
        title={'Flip your document and scan the back. Make sure you scan the barcode.'}
        onTakePhoto={handleTakePhoto}
      />

      <div className="intro-text">
        <h3>Please make sure that the barcode is visible and readable.</h3>
        <h2>
          Done with the back? <b>Continue to Take a Selfie</b>
        </h2>
        <div className="camera-animation">
          <Lottie config={animationOptions} />
        </div>
        <button onClick={handleRestartPhoto} className="button is-pill is-green">
          Open Camera
        </button>
      </div>

      <PhotoPreviewWithControls
        alt={'Back Preview'}
        imageData={photo}
        onRepeat={handleRestartPhoto}
        onContinue={handleContinue}
        loading={status === 'create'}
      />

      {error && (
        <div role="alert" className="alert toast is-error u-margin-top-xl">
          <AlertCircle /> {error}
        </div>
      )}
    </div>
  )
}
