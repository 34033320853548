import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './StepPhoneVerification.sass'
import {
  IPhoneVerificationDetails,
  PhoneNumberVerificationForm,
} from '../../Forms/PhoneNumberVerificationForm/PhoneNumberVerificationForm'
import { PhoneNumberCodeCheckForm } from '../../Forms/PhoneNumberCodeCheckForm/PhoneNumberCodeCheckForm'

interface IStepPhoneVerification {
  className?: string
  style?: React.CSSProperties
}

export const StepPhoneVerification: React.FC<IStepPhoneVerification> = (props: IStepPhoneVerification) => {
  const history = useHistory()
  const [phoneVerificationDetails, setPhoneVerificationDetails] = useState<IPhoneVerificationDetails>({
    phoneNumber: undefined,
    customerName: 'User',
  })
  const handleSubmit = async (data: IPhoneVerificationDetails) => {
    setPhoneVerificationDetails(data)
    // setPhoneNumber(data.phoneNumber)
  }
  const handleOnCompleted = async () => {
    history.push('/onboarding/email')
  }

  const getPhoneNumberEnd = () => {
    const phoneNumber = phoneVerificationDetails.phoneNumber!
    return phoneNumber.slice(phoneNumber.length - 3)
  }
  return (
    <div data-testid="step-phone-verification" className={`text-center`}>
      <div className="max-wrapper">
        <h3>Phone Verification</h3>
        <h2>
          {!phoneVerificationDetails.phoneNumber
            ? 'Verify your identity. We do this by sending a code to your cell phone'
            : `Hello ${
                phoneVerificationDetails.customerName
              }, type in the code sent to number ending  in ${getPhoneNumberEnd()} the field below`}
        </h2>
        {!phoneVerificationDetails.phoneNumber ? (
          <PhoneNumberVerificationForm onSubmit={handleSubmit} />
        ) : (
          <PhoneNumberCodeCheckForm
            phoneNumber={phoneVerificationDetails.phoneNumber}
            onCompleted={handleOnCompleted}
          />
        )}
      </div>
    </div>
  )
}
