import CoreAPI from './CoreAPI'

export interface IVerificationCreationObject {
  phoneNumber: string
}
export interface IVerificationCheckObject {
  phoneNumber: string,
  code: string
}
export interface IPhoneVerificationResponse {
  sid: string
  to: string
  status: string
}

/* Logic for creating and retrieving PhoneVerification information from core api */
class PhoneVerification extends CoreAPI {
 
  public async createPhoneVerification(verificationCreationObject: IVerificationCreationObject) {
    return await this.postRequest(`/phone-verification`, verificationCreationObject, true)
  }
  public async checkPhoneVerification(verificationCheckObject: IVerificationCheckObject): Promise<IPhoneVerificationResponse> {
    return await this.postRequest(`/phone-verification-check`, verificationCheckObject)
  }
}

export default new PhoneVerification()
