import React from 'react'
import { useUID } from 'react-uid'
import { ErrorMessage, Label } from '../..'
import './Input.sass'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
interface IProps extends InputProps {
  type?: string
  label?: string
  name: string
  placeholder?: string
  defaultValue?: string
  className?: string
  errors?: any
  pattern?: string
  disabled?: boolean
  autoFocus?: boolean
  max?: string
  autocomplete?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const uid = useUID()
  return (
    <div className={`form-group ${props.className}`} data-testid={uid}>
      <ErrorMessage errors={props.errors} />
      <div className="form-fields">
        <input
          className="form-input"
          type={props.type}
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          id={uid}
          onChange={props.onChange}
          placeholder={props.placeholder}
          name={props.name}
          data-testid={`input-${props.name}`}
          ref={ref}
          defaultValue={props.defaultValue}
          pattern={props.pattern}
          disabled={props.disabled}
          max={props.max}
        />
        {props.label && <Label for={uid}>{props.label}</Label>}
      </div>
    </div>
  )
})

Input.defaultProps = {
  type: 'text',
  className: '',
}
